<template>
  <v-container class="fs-organizer-container">
    <Title layout>{{ title }}</Title>
    <v-form>
      <v-layout
        row
        justify-space-around
        wrap
      >
        <v-flex class="pr-2" md6>
          <v-text-field
            label="Наименование"
            :value="item.name"
            readonly
          ></v-text-field>
          <v-text-field
            label="Цена, руб (на 100 г)"
            :value="convertToRubles(item.price)"
            readonly
          ></v-text-field>
           <v-autocomplete
             v-if = "setting('recipe_food_category')"
             label="Раздел"
             v-model="currentFoodCategory"
             :items="foodCategories"
             clearable
             item-text="name"
             item-value="id"
          ></v-autocomplete>
          <v-text-field
            label="Энергетическая ценность, кКал/кДж (на 100 г)"
            :value="item.energy"
            readonly
          ></v-text-field>
          <v-text-field
            label="Белки, г (на 100 г)"
            :value="item.protein"
            readonly
          ></v-text-field>
          <v-text-field
            label="Жиры, г (на 100 г)"
            :value="item.fats"
            readonly
          ></v-text-field>
          <v-text-field
            label="Углеводы, г (на 100 г)"
            :value="item.carbs"
            readonly
          ></v-text-field>
          </v-flex>
          <v-flex class="pr-2" md6>

          <v-text-field
            label="Витамин A (мкг на 100 г)"
            :value="item.vitaminA"
            readonly
          ></v-text-field>
          <v-text-field
            label="Витамин В1 (мкг на 100 г)"
            :value="item.vitaminB1"
            readonly
          ></v-text-field>
          <v-text-field
            label="Витамин C (мкг на 100 г)"
            :value="item.vitaminC"
            readonly
          ></v-text-field>
          <v-text-field
            label="Витамин E (мкг на 100 г)"
            :value="item.vitaminE"
            readonly
          ></v-text-field>
          <v-text-field
            label="Минеральные вещества Ca (мкг на 100 г)"
            :value="item.miniralCa"
            readonly
          ></v-text-field>
          <v-text-field
            label="Минеральные вещества Mg (мкг на 100 г)"
            :value="item.miniralMg"
            readonly
          ></v-text-field>
          <v-text-field
            label="Минеральные вещества Fe (мкг на 100 г)"
            :value="item.miniralFe"
            readonly
          ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
                v-if = "setting('recipe_food_category')"
                :value="item.recipe"
                label="Номер рецепта"
                readonly
               ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-checkbox
            :value="item.isAdditional"
            :ripple="false"
            label="Дополнительное"
            readonly
          ></v-checkbox>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="dishAgeCategoryHeaders"
            :items="item.ageCategoires"
            hide-actions
            class="elevation-1"
          >
            <template v-slot:items="props">
              <tr>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.gramms }} гр.</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-flex text-md-right mt-3>
        <v-btn
          class="mx-0"
          @click="$router.push({ name: 'Dishes' })"
        >Назад</v-btn>
      </v-flex>
    </v-form>
  </v-container>
</template>

<script>
import Title from '@/ui-components/Title'

import { getOneDish } from '@/router/routes/organizer'
import store from '@/store'
import DishFactory from '@/factories/organizer/dish-factory'
import numable from '@/mixins/balancable'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'DishEdit',
  mixins: [ numable ],
  components: {
    Title
  },
  data() {
    return {
      item: new DishFactory(),
      dishAgeCategoryHeaders: [
        { text: 'Возрастная категория', value: 'age-category' },
        { text: 'Выход блюда, г', value: 'gramms' }
      ],
      currentFoodCategory: null,
    }
  },
  computed: {
    title() {
      return `Блюдо: ${this.item.name}`
    },
    ...mapGetters([ 'foodCategories', 'setting' ])
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async init() {
      try {
        const response = await getOneDish(this.$route.params.id)
        const {
          data: { data: dish }
        } = response
        this.item = {
          id: dish.id,
          name: dish.name,
          foodCategory: dish.food_category,
          recipe: dish.recipe || 'Не заполнен',
          energy: dish.energy_value || 0,
          protein: dish.protein || 0,
          fats: dish.fat || 0,
          carbs: dish.carbohydrates || 0,
          price: dish.price || 0,
          isAdditional: dish.is_additional,
          vitaminA: dish.vitamin_a || 0,
          vitaminB1: dish.vitamin_b1 || 0,
          vitaminC: dish.vitamin_c || 0,
          vitaminE: dish.vitamin_e || 0,
          miniralCa: dish.miniral_ca || 0,
          miniralFe: dish.miniral_fe || 0,
          miniralMg: dish.miniral_mg || 0,
          ageCategoires: [
            {
              id: 1,
              alias: 'weightPrimary',
              name: '7-11 лет',
              gramms: dish.weight_primary || 0
            },
            {
              id: 2,
              alias: 'weightPrimary',
              name: '12 лет и старше',
              gramms: dish.weight_middle || 0
            }
          ]
        }
        this.currentFoodCategory = dish.food_category
      } catch (error) {
        // notify.error('Ошибка получения блюда') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
