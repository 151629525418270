import Vue from 'vue'


export const getEsiaStatus = () => axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.esia.status
)

export const setEsiaSnils = (payload) => axios.post(
  Vue.config.server + '/' + Vue.config.api.organizer.esia.setSnils,
  payload
)

export const doUserAuthEsia = key => axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.esia.success + `?authCode=${key}`
)

export const getClassDetails = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.orders.classDetails
      .replace('$schoolId', payload.schoolId)
      .replace('$classId', payload.classId)
      .replace('$date', payload.date)
  )

export const clearFutureDailyMenu = () => axios.delete(
    Vue.config.server + '/' + Vue.config.api.organizer.dialyMenu.clearFuture
)

export const getUpdatesNews = id => axios.get(Vue.config.server + '/' + Vue.config.api.organizer.updates)

export const getStorehouses = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.storehouses.all,
    { params: payload.params }
  )

export const getTransactions = function(params) {
  if (this.pupilId === null) return
  const url = Vue.config.api.organizer.balance.transactions.replace('$pupil_id', this.pupilId)
  return axios.get(
    `${Vue.config.server}/${url}`,
    { params }
  )
}

export const getAllBuffetApplication = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.applications.all,
    payload
  )


export const fetchCategoryOP = function(payload) {
  const url = Vue.config.api.organizer.buffet.category.all
  return axios.get(`${Vue.config.server}/${url}`, payload)
}

export const fetchOneCategoryOP = function(id) {
  const url = Vue.config.api.organizer.buffet.category.one.replace('$id', id)
  return axios.get(`${Vue.config.server}/${url}`)
}


export const createCategoryOP = function(payload) {
  const url = Vue.config.api.organizer.buffet.category.all
  return axios.post(`${Vue.config.server}/${url}`, payload)
}

export const deleteCategoryOP = function(id) {
  const url = Vue.config.api.organizer.buffet.category.one.replace('$id', id)
  return axios.delete(`${Vue.config.server}/${url}`, id)
}

export const updateCategoryOP = function(payload) {
  const url = Vue.config.api.organizer.buffet.category.one.replace('$id', payload.id)
  return axios.put(`${Vue.config.server}/${url}`, payload)
}


export const hasReserve = () => {
  return axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.orders.hasReserve
  )
}


export const createBuffetProductDict = function(payload) {
  const url = Vue.config.api.organizer.buffet.productDict.all
  return axios.post(`${Vue.config.server}/${url}`, payload)
}

export const editBuffetProductDict = function(payload) {
  const url = Vue.config.api.organizer.buffet.productDict.one.replace('$product_id', payload.id)
  return axios.put(`${Vue.config.server}/${url}`, payload.data)
}

export const deleteBuffetProductDict = function(id) {
  const url = Vue.config.api.organizer.buffet.productDict.one.replace('$product_id', id)
  return axios.delete(`${Vue.config.server}/${url}`)
}

export const getBuffetProductDict = function(payload) {
  const url = Vue.config.api.organizer.buffet.productDict.all
  return axios.get(`${Vue.config.server}/${url}`, payload)
}

export const getBUffetProductDict = function(id) {
  const url = Vue.config.api.organizer.buffet.productDict.one.replace('$product_id', id)
  return axios.get(`${Vue.config.server}/${url}`)
}

export const getDishesSearch = params =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.dishes.search, { params }
  )

export const getDishes = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.dishes.all,
    payload
  )

export const getArchivedDishes = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.dishes.archive,
    payload
  )


export const getStudentsBalanceBySupplier = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.students.balanceHistoryBySupplier,
    { params: payload.params }
  )

export const getOneDish = id =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.dishes.one.replace('$id', id)
  )

export const archiveOneDish = id =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.dishes.oneArchive.replace('$id', id)
  )

export const unarchiveOneDish = id =>
  axios.delete(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.dishes.oneArchive.replace('$id', id)
  )

export const updateDish = payload =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.dishes.one.replace('$id', payload.id),
    payload.data
  )

export const createDish = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.dishes.all,
    payload
  )

export const deleteSelectedDishes = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.dishes.destroy,
    payload
  )

export const deleteDish = id =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.dishes.one.replace('$id', id)
  )

export const createComplex = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.complexes.all,
    payload
  )


export const getReserveComplex = () =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.complexes.reserve
  )

export const setReserveComplex = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.complexes.reserve,
    payload
  )

export const getBuffetTransactions = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.parent.buffet.transactions.all,
    payload
  )

export const getBuffetProducts = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.parent.buffet.products.all,
    payload
  )

export const getDefaultComplex = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.defaultComplex.all,
    payload
  )

export const createDefaultComplexes = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.defaultComplex.all,
    payload
  )


export const getProducts = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.buffet.transactions.all,
    payload
  )

export const getComplexes = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.complexes.all,
    payload
  )

export const getStashedComplexes = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.stashedComplexes.all
  )

export const deleteSelectedComplexes = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.complexes.destroy,
    payload
  )

export const stashSelectedComplexes = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.complexes.stash,
    payload
  )

export const restoreStashedComplexes = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.stashedComplexes.restore,
    payload
  )

export const deleteComplex = id =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.complexes.one.replace('$id', id)
  )

export const getOneComplex = id =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.complexes.one.replace('$id', id)
  )

// TODO Новый (проверить другие и заменить)
export const getComplex = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.complexes.item(payload.id),
    { params: payload.params }
  )

export const updateComplex = (payload) =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.complexes.item(payload.id),
    payload.data
  )

export const getOneStashedComplex = id =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.stashedComplexes.one.replace('$id', id)
  )

// additional menu

export const getAdditionalMenu = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.dialyMenu.additionalMenu,
    payload
  )

export const setAdditionalMenu = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.dialyMenu.additionalMenu,
    payload
  )

export const updateAdditionalMenu = payload =>
  axios.patch(
    Vue.config.server + '/' + Vue.config.api.organizer.dialyMenu.additionalMenu,
    payload
  )

export const deleteAdditionalMenu = payload =>
  axios.delete(
    Vue.config.server + '/' + Vue.config.api.organizer.dialyMenu.additionalMenu,
    payload
  )

//

export const getOrganizerFoodTypes = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.foodTypes,
    payload
  )

export const assignComplexesToTemplate = payload =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.dialyMenu.one.replace('$id', payload.id),
    payload.params
  )

export const clearTemplateComplexes = payload =>
  axios.delete(
    Vue.config.server + '/' + Vue.config.api.organizer.dialyMenu.clear.replace('$id', payload.id)
  )

export const createTemplateMenu = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.dialyMenu.all,
    payload.params
  )

export const getComplexesTemplate = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.dialyMenu.all,
    payload
  )

export const getSupplierMenuTemplatesByDays = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.menuTemplateByDays.all,
    payload
  )

export const getSupplierMenuTemplatesByOneDay = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.menuTemplateByDays.byDay,
    payload
  )

export const getOneComplexTemplate = id =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.dialyMenu.one.replace('$id', id)
  )

export const uploadDishesFile = (payload, config) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.dishes.uploadFile,
    payload,
    config
  )

export const uploadInvoicesFile = (payload, config) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.invoices.upload,
    payload,
    config
  )

export const uploadInvoicesFileCasher = (payload, config) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.invoices.uploadCasher,
    payload,
    config
  )

export const activateTemplates = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.dialyMenu.activate,
    payload
  )

export const getOrganizerInfo = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.organizer.info, payload)

export const getBanking = () => axios.get(Vue.config.server + '/' + Vue.config.api.organizer.banking)

export const getCurrentSchoolOrders = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.orders.school
        .replace('$schoolId', payload.schoolId)
        .replace('$date', payload.date)
  )

export const getCurrentSchoolArchiveOrders = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.orders.schoolArchive
      .replace('$schoolId', payload.schoolId)
      .replace('$date', payload.date)
  )

export const getCurrentOrder = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.orders.details
        .replace('$schoolId', payload.schoolId)
        .replace('$classId', payload.classId)
        .replace('$date', payload.date)
  )

export const getCurrentArchiveOrder = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.orders.detailsArchive
      .replace('$schoolId', payload.schoolId)
      .replace('$classId', payload.classId)
      .replace('$date', payload.date)
  )

export const getOrderReport = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.orders.report,
    payload
  )

export const getOrderReportStatus = payload => {
  console.log(payload)
  return axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.orders.reportStatus.replace(
        '$task_id',
        payload.task_id
      ),
    { preloaderState: false }
  )
}

export const getOrderReportDownload = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.orders.reportDownload.replace(
        '$task_id',
        payload.task_id
      ),
    { responseType: 'blob', preloaderState: false }
  )
export const printOrder = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.orders.download,
    payload,
    { responseType: 'blob' }
  )

export const printArchiveOrder = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.orders.downloadArchive,
    payload,
    { responseType: 'blob' }
  )

export const getSchools = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.schools.all,
    payload
  )


export const getArchiveSchools = (year, month, day) =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.schools.archive.replace('$year', year).replace('$month', month).replace('$day', day)
  )

export const getSchool = id =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.schools.one.replace('$id', id)
  )

export const getClasses = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.schools.classes.replace(
        '$school_id',
        payload.school_id
      )
  )

export const updateSchool = payload =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.schools.one.replace('$id', payload.id),
    payload.params
  )

export const printMenu = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.orders.print,
    payload,
    { responseType: 'blob', preloaderState: false }
  )

export const getBudgetTaskId = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.budget.all,
    payload,
    { preloaderState: false }
  )

export const getBudgetStatus = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.budget.one.replace('$task_id', payload.task_id),
    { preloaderState: false }
  )

export const getBudgetFile = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.budget.download.replace(
        '$task_id',
        payload.task_id
      ),
    { responseType: 'blob', preloaderState: false }
  )

export const getInvoicesHistory = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.invoices.all,
    payload
  )
// PRINT FILE
export const printFileTaskId = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.invoices.print,
    payload,
    { preloaderState: false }
  )

export const getPrintFileStatus = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.invoices.printProgress.replace(
        '$task_id',
        payload.task_id
      ),
    { preloaderState: false }
  )

export const getPrintFile = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.invoices.printDownload.replace(
        '$task_id',
        payload.task_id
      ),
    { responseType: 'blob', preloaderState: false }
  )
// agreement
export const getOfferList = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.agreement.all,
    payload
  )

export const createOffer = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.agreement.all,
    payload.params
  )

export const createPrivacyPolitics = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.privacy.all,
    payload
  )

export const getPrivacy = () =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.privacy.all
  )

export const getOffer = id =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.agreement.one.replace('$id', id)
  )
export const getOfferLatest = id =>
  axios.get(Vue.config.server + '/' + Vue.config.api.organizer.agreement.latest)

export const deleteOffer = id =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.agreement.one.replace('$id', id)
  )
export const studentsOffer = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.agreement.students,
    payload
  )
// REPORTS
export const getAllReports = () =>
  axios.get(Vue.config.server + '/' + Vue.config.api.organizer.reports.all)

export const reportFileTaskId = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.reports.one.replace('$id', payload.id),
    payload.data,
    { preloaderState: false }
  )

export const reportFileTaskIdSchool = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.reports.schoolOne
        .replace('$id', payload.id)
        .replace('$school_id', payload.school_id),
    payload.data,
    { preloaderState: false }
  )

export const reportFileArchiveBalance = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.reports.archiveBalance,
    payload.data,
    { preloaderState: false }
  )

export const reportFormFileTaskId = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.reports.form
        .replace('$id', payload.id)
        .replace('$form_id', payload.form_id),
    payload.data,
    { preloaderState: false }
  )


export const reportForm2 = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.reports.report2,
    { school_id: payload.school_id, ...payload.data },
    { preloaderState: false }
  )

export const reportFileStatus = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.reports.status
        .replace('$id', payload.id)
        .replace('$task_id', payload.task_id),
    { preloaderState: false }
  )

export const reportPrintFile = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.reports.download
        .replace('$id', payload.id)
        .replace('$task_id', payload.task_id),
    { responseType: 'blob', preloaderState: false }
  )
// /
// dayMenu
export const getAllDayMenu = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.dayMenu.all,
    payload
  )
export const getDayMenu = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.dayMenu.one.replace('$id', payload.id)
  )
export const setDayMenu = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.dayMenu.all,
    payload.data
  )
// Balance
export const getStudentsBalance = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.students.balanceHistory,
    payload
  )
// Notifications
export const getNotifications = () =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.notifications.all
  )

export const setNotificationAdmin = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.notificationsAdmin.all,
    payload.data
  )

export const setNotificationEveryoneAdmin = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.notificationsAdmin.everyone,
    payload.data
  )

export const setNotification = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.notifications.all,
    payload.data
  )

export const setNotificationEveryone = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.notifications.everyone,
    payload.data
  )

export const updateNotification = payload =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.notifications.update.replace('$id', payload.id),
    payload.data
  )

export const getFilteredStudents = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.students.filtered
        .replace('$schoolId', payload.schoolId)
        .replace('$classId', payload.classId),
    { params: payload.params }
  )

export const updateStudentBalance = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.balance.start,
    payload
  )

export const getOneStudent = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.organizer.students.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id)
  )

export const getOneStudentBalance = id =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.students.balance
      .replace('$id', id)
  )

export const getOneStudentReceipts = id =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.students.receipts
      .replace('$id', id)
  )

export const postOneStudentReceipts = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.students.receiptsAdd, payload
  )

// Users
export const getUsers = () =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.users.all
  )

export const createUser = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.users.all,
    payload
  )

export const blockUser = id =>
  axios.delete(
    Vue.config.server + '/' + Vue.config.api.organizer.users.one
      .replace('$userId', id)
  )

export const updateUser = payload =>
  axios.patch(
    Vue.config.server + '/' + Vue.config.api.organizer.users.one
      .replace('$userId', payload.id),
    payload
  )

export const getEvents = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.events,
    payload
  )

export const getOrdersLogs = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.orders.logs,
    { params: payload.params }
  )

export const getAdditionalDishes = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.additionalDishes.index,
    { params: payload.params }
  )

export const deleteAdditionalFoodSet = payload =>
  axios.delete(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.additionalDishes.deleteFoodSet.replace('$foodSetID', payload.id),
    payload
  )

export const createAdditionalFood = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.additionalDishes.index,
    payload
  )

export const createAdditionalDishes = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.additionalDishes.dishes_create,
    payload
  )

export const updateAdditionalDishes = payload =>
  axios.put(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.additionalDishes.index + payload.id,
    payload
  )

export const deleteAdditionalDishes = id =>
  axios.delete(
    `${Vue.config.server}/${Vue.config.api.organizer.additionalDishes.index}${id}/`
  )

export const getExpelledRegister = () =>
  axios.get(
    `${Vue.config.server}/${Vue.config.api.organizer.expelled.getRegister}`
  )

export const searchExpelledRegister = (payload) =>
  axios.post(
    `${Vue.config.server}/${Vue.config.api.organizer.expelled.getRegister}`, payload
  )

export const createCashbackRequest = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.organizer.cashback.create,
    payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )

export const getCashbackRequests = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.cashback.index,
    { params: payload.params }
  )

export const getCashbackDocument = id =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.cashback.one.replace('$id', id),
    { responseType: 'blob', preloaderState: false }
  )

export const setCashbackDocumentStatus = payload =>
  axios.patch(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.cashback.status.replace('$id', payload.id).replace('$status', payload.status),
    payload
  )

export const getReportBalanceTaskId = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.reports.balance,
    payload.data,
    { preloaderState: false }
  )

export const reportBalanceFileStatus = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.reports.balanceStatus
      .replace('$task_id', payload.task_id),
    payload.data,
    { preloaderState: false }
  )

export const reportBalancePrintFile = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.reports.balanceDownload
      .replace('$task_id', payload.task_id),
    { responseType: 'blob', preloaderState: false }
  )

export const getFoodCopyKey = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.foodCopy
  )

export const postFoodCopyKey = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.foodCopy,
    payload,
    { preloaderState: false }
  )

/**
 * Получает список бывших школ.
 * @returns {Promise<Object>} Объект с данными о бывших школах.
 */
export const getFormerSchools = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.organizer.formerSchools.schools,
  )

/**
 * Получает историю платежей для бывших школ с учётом фильтров.
 * @param {Object} payload - Объект с параметрами запроса, включая фильтры, даты и параметры пагинации.
 * @param {string} payload.params.school - Идентификатор выбранной школы.
 * @param {string} [payload.params.last_name] - Фамилия ученика
 * @param {string} [payload.params.pupil_scope] - Охват учеников
 * @param {string} [payload.params.start_date] - Начальная дата для фильтрации платежей.
 * @param {string} [payload.params.end_date] - Конечная дата для фильтрации платежей.
 * @param {number} [payload.params.page] - Текущая страница.
 * @param {number} [payload.params.page_size] - Количество записей на странице.
 * @returns {Promise<Object>} Объект с данными о платежах и информацией о пагинации.
 */
export const getPaymentsHistoryFormerSchools = (payload) =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.organizer.formerSchools.payments,
    { params: payload.params }
  )