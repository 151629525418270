<script setup>
import moment from 'moment/moment'
import { ref, watch } from 'vue'

const props = defineProps([
  'tableData',
  'pagination'
])
// Прокидываем объект pagination через пропсы, чтобы извне установить общее кол-во элементов в бд.
// Использую локальный объект пагинации, чтобы избежать мутации пропсов.
// Если props.pagination изменяется из родительского компонента, то меняем и здесь.
// Если пагинация изменяется локально, то отправляем emit в родительский компонент.
const localPagination = ref(props.pagination)
watch(() => props.pagination, (newValue) => {
  localPagination.value = newValue
})
// Emit используем для запроса новых данных с сервера при выборе другой страницы на фронте.
const emit = defineEmits([ 'updatePagination' ])

const headers = [
  { text: 'Время', value: 'timestamp' },
  { text: 'Раздел', value: 'content_type__model' },
  { text: 'Действие', value: 'action' },
  { text: 'Сущность', value: 'object_id' },
  { text: 'Изменение', value: 'changes' },
]

const tryFormatDate = (value) => {
  if (moment(value).isValid()) {
    return moment(value).format("YYYY-MM-DD HH:mm:ss")
  }
  return value
}

const formatCreate = (values) => {
  const data = []
  Object.keys(values).forEach(key => {
    data.push({
      key: key,
      value: values[key] ? values[key] : 'Пусто'
    })
  })
  return data
}

const formatChanges = (changesObject) => {
  const data = []
  Object.keys(changesObject).forEach(key => {
    let oldValue = changesObject[key].old || "Ничего"
    let newValue = changesObject[key].new || "Ничего"
    oldValue = tryFormatDate(oldValue)
    newValue = tryFormatDate(newValue)
    data.push({ key: key, old: oldValue, new: newValue })
  })
  return data
}

const actionTranslate = {
  'update': 'Изменение',
  'create': 'Создание',
  'delete': 'Удаление',
  'post_add': 'Добавление (M2m)',
  'post_clear': 'Очистка (M2m)',
  'pre_remove': 'Удаление (M2m)'
}
const actionIcon = {
  'update': 'edit_note',
  'create': 'add',
  'delete': 'delete',
  'post_add': 'post_add',
  'post_clear': 'playlist_remove',
  'pre_remove': 'playlist_remove'
}
const subjectTranslate = {
  'pupil': 'Ученик',
  'mealtype': 'Тип питания',
  'mealtypesupplieroptions': 'Назначение типа питания',
  'privilege': 'Льгота',
  'complect': 'Комплекс',
  'daymenu': 'Ежедневное меню',
  'daymenucomplectmapping': 'Комплексы в ежедневном меню',
  'menutemplate': 'Шаблон меню',
  'menutemplatecomplectmapping': 'Данные в шаблоне меню',
  'reservemenutemplate': 'Резервный шаблон',
  'supplier': 'Оператор питания',
  'school': 'Школа',
  'suppliertransfer': 'Назначение школы комбинату'
}
const subjectIcon = {
  'pupil': 'face',
  'mealtype': 'menu_book',
  'mealtypesupplieroption': 'food_bank',
  'privilege': 'redeem',
  'complect': 'bento',
  'daymenu': 'menu_book',
  'daymenucomplectmapping': 'settings_suggest',
  'menutemplate': 'menu_book',
  'menutemplatecomplectmapping': 'settings_suggest',
  'reservemenutemplate': 'menu_book',
  'supplier': 'local_shipping',
  'school': 'school',
  'suppliertransfer': 'rv_hookup',
}
</script>

<template>
<v-data-table
  :items="tableData"
  :headers="headers"
  class="elevation-1"
  :pagination.sync='localPagination'
  :total-items='pagination.totalItems'
  @update:pagination='v => emit("updatePagination", v)'
>
  <template v-slot:items="props">
    <td>{{ moment(props.item.timestamp).format('YYYY-MM-DD HH:mm:ss') }}</td>
    <td>
      <v-chip color="indigo" text-color="white" class='px-1'>
          <v-icon size='20' class='mr-2'>
            {{ subjectIcon[props.item.content_type__model] }}
          </v-icon>
          <span>{{ subjectTranslate[props.item.content_type__model] }}</span>
      </v-chip>
    </td>
    <td>
      <v-chip color="green" text-color="white">
        <v-icon size='20' class='mr-2'>
          {{ actionIcon[props.item.action] }}
        </v-icon>
        <span>{{ actionTranslate[props.item.action] }}</span>
      </v-chip>
    </td>
    <td>
      <v-chip color="pink lighten-2" text-color="white">
        {{ props.item.object_id }}
      </v-chip>
    </td>
    <td>
      <div v-if="props.item.action === 'update'">
        <div v-for="item in formatChanges(props.item.changes)" class="flex row">
          <v-chip color="white" class="">
            {{ item.key }}
            <v-chip color="red lighten-3" text-color="white" small>
              {{ item.old}}
              <v-icon>
                chevron_right
              </v-icon>
              <v-chip color="green darken-2" text-color="white" small>
                {{ item.new }}
              </v-chip>
            </v-chip>
          </v-chip>
        </div>
      </div>
      <div v-else-if="props.item.action === 'create'">
        <div v-for="item in formatCreate(props.item.changes)">
          <v-chip color="white">
            {{ item.key }}
            <v-chip small color="green darken-2" text-color="white">
              {{ item.value }}
            </v-chip>
          </v-chip>
        </div>
      </div>
      <div v-else>
        {{ props.item.changes }}
      </div>
    </td>
  </template>
</v-data-table>
</template>

<style>
.centericon {
  vertical-align: middle;
}
</style>
