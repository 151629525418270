<template>
  <v-container fluid class="fs-teacher-container">
    <!-- Title -->
    <v-layout class="fs-teacher-order" row mb-3>
      <div class="d-flex row justify-space-between">
        <h1>{{ title }} &mdash; {{ schoolName }}</h1>
        <context-help-modal
          :context-key="contextKeys.admin.orders.statusInfo"
        />
      </div>
    </v-layout>

    <!-- Caution -->
    <v-layout class="mb-3" v-if="infoModal">
      <v-flex>
        <v-card>
          <v-card-title class="pb-0 pt-1 justify-space-between">
            <h3>Внимание</h3>
            <v-btn
              fab
              dark
              small
              style="width: 20px; height: 20px"
              color="primary"
              @click="closeInfoModal"
            >
              <v-icon style="font-size: 14px"> close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-0">
            <p>
              Рекомендуем не сохранять\подтверждать заявки более чем на день
              вперед, т.к. в случае каких-либо изменений сохраненные и
              подтвержденные заявки уже не переформируются в соответствии с
              новыми обстоятельствами.
            </p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Filters & reportsNavBar -->
    <v-layout
      :class="{ 'fs-header-filter': !darkTheme, 'justify-space-between': true }"
    >
      <div class="d-flex" style="flex-direction: column; max-width: 1366px">
        <div class="d-flex row">
          <element-group title="Выбранная дата" class="mr-2">
            <div class="d-flex row">
              <with-tooltip text="Выбор даты заявок">
                <v-menu
                  v-model="datepickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  style="display: none"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :prepend-inner-icon="darkTheme ? 'event' : ''"
                      :prepend-icon="!darkTheme ? 'event' : ''"
                      :outline="darkTheme"
                      v-model="viewData"
                      readonly
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    class="fs-datepicker"
                    prev-icon="keyboard_arrow_left"
                    next-icon="keyboard_arrow_right"
                    locale="ru"
                    first-day-of-week="1"
                    no-title
                    v-model="date"
                    @input="selectDate"
                    @change="handlerSearch"
                  ></v-date-picker>
                </v-menu>
              </with-tooltip>
              <with-tooltip text="Показать заявки за выбранную дату">
                <v-btn
                  flat
                  class="fs-btn fs-teacher-btn__show v-btn--active mx-1"
                  style="max-width: 100px"
                  @click="handlerSearch"
                >
                  Показать
                </v-btn>
              </with-tooltip>
            </div>
          </element-group>
          <element-group title="Корпус" :max="200">
            <with-tooltip text="Выбор корпуса школы">
              <div class="mr-1">
                <v-autocomplete
                  v-model="currentHouseId"
                  :items="[{ id: null, name: 'Все' }, ...houses]"
                  :item-text="'name'"
                  :item-value="'id'"
                  flat
                  dense
                  :outline="darkTheme"
                  @change="selectHouse"
                  :class="{ 'fs-admin-complex-autocomplete': !darkTheme }"
                  hide-details
                ></v-autocomplete>
              </div>
            </with-tooltip>
          </element-group>
        </div>

        <!-- reportsNavBar -->
        <element-group title="Отчеты">
          <ul>
            <li v-for="(v, k) in reportsNavBar" :key="k">
              <with-tooltip :text="v.tooltip">
                <v-btn
                  @click="v.handler"
                  class="fs-btn fs-teacher-btn__excel mr-3"
                >
                  {{ v.title }}
                </v-btn>
              </with-tooltip>
            </li>
          </ul>
        </element-group>
      </div>
      <div>
        <div class="d-flex row"></div>
      </div>
    </v-layout>

    <v-layout row mb-3 v-if="isInCurrentLearnYearOrLater">
      <div class="d-flex" style="flex-direction: column; max-width: 1366px">
        <element-group v-if="!municipalityView" title="Работа с заявками">
          <ul>
            <li>
              <div>
                <with-tooltip
                  text="При нажатии будет открыто окно для работы с отменой заказов по школе
                             (можно выбрать классы и статусы заказов)"
                >
                  <OrderCancelModal
                    v-if="setting('admin_cancel_orders')"
                    :dialog.sync="showModal"
                    :school-id="schoolId"
                    @reloadOrders="this.loadOrders"
                    :day="day"
                    :month="month"
                    :year="year"
                  >
                  </OrderCancelModal>
                </with-tooltip>
              </div>
            </li>

            <li v-for="(v, k) in navBarForWorkingWithOrders" :key="k">
              <div>
                <with-tooltip :text="v.tooltip">
                  <v-btn
                    v-show="v.show"
                    @click="v.handler"
                    class="fs-btn fs-teacher-btn__cancel mr-3"
                  >
                    <v-icon
                      v-if="v.icon"
                      small
                      class="pr-1"
                      :color="v.iconColor || 'white'"
                    >
                      {{ v.icon }}
                    </v-icon>
                    {{ v.title }}
                  </v-btn>
                </with-tooltip>
              </div>
            </li>
          </ul>
        </element-group>
      </div>
    </v-layout>

    <!-- Table -->
    <v-card>
      <v-card-text>
        <div class="mb-2">
          Всего питающихся:
          <span class="font-weight-bold">
            {{ relevantOrdersCount }}
          </span>
        </div>
        <v-flex>
          <v-data-table
            :headers="headers"
            :pagination.sync="pagination"
            :items="items"
            hide-actions
            no-data-text="Нет данных"
            class="elevation-1 fs-teacher-table"
            item-key="name"
            :custom-sort="customSort"
          >
            <template v-slot:items="props">
              <tr
                @click.prevent="
                  municipalityView
                    ? navigateTo(
                        'MunicipalityOrdersTableView',
                        props.item,
                        currentHouseId
                      )
                    : navigateTo(
                        'AdminOrdersTableView',
                        props.item,
                        currentHouseId
                      )
                "
              >
                <td class="cursor-pointer">
                  {{ props.item.date }}
                </td>
                <td class="cursor-pointer">
                  {{ props.item.class.name }}
                </td>
                <td class="cursor-pointer">
                  {{ props.item.statusName }}
                </td>
                <td
                  v-if="workingDaysInOrders"
                  class="cursor-pointer grid-list-md text-xs-center"
                >
                  <v-icon
                    :style="
                      props.item.is_working_day ? 'color: green' : 'color: red'
                    "
                  >
                    {{ props.item.is_working_day ? 'done' : 'close' }}
                  </v-icon>
                </td>
                <td class="grid-list-md text-xs-center">
                  <v-flex v-if="props.item.ordersErrors.length > 0">
                    <v-tooltip class="rounded-xl" top color="yellow">
                      <template v-slot:activator="{ on }">
                        <span
                          v-on="on"
                          class="subheading error--text font-weight-light"
                        >
                          <v-icon
                            color="warning"
                            icon="mdi-information"
                            size="medium"
                          >
                            warning
                          </v-icon>
                        </span>
                      </template>
                      <ul
                        v-for="(item, index) in props.item.ordersErrors"
                        :key="index"
                      >
                        <li class="black--text">
                          <b>{{ item.pupil_full_name }}</b>
                          - {{ item.error }}
                        </li>
                      </ul>
                    </v-tooltip>
                  </v-flex>
                </td>
                <td>
                  <v-flex row>
                    <with-tooltip
                      text="Просмотр данных заявки по классу. Редактирование недоступно."
                    >
                      <v-btn
                        flat
                        @click.stop="
                          municipalityView
                            ? navigateTo(
                                'MunicipalityOrdersTableView',
                                props.item,
                                currentHouseId
                              )
                            : navigateTo(
                                'AdminOrdersTableView',
                                props.item,
                                currentHouseId
                              )
                        "
                        class="fs-btn fs-teacher-btn__details"
                      >
                        Смотреть
                      </v-btn>
                    </with-tooltip>
                    <with-tooltip
                      text="Просмотр данных заявки по классу. Редактирование доступно."
                    >
                      <v-btn
                        flat
                        @click.stop="
                          navigateTo(
                            'AdminOrdersTableEdit',
                            props.item,
                            currentHouseId
                          )
                        "
                        class="fs-btn fs-teacher-btn__edit"
                        v-if="!municipalityView"
                      >
                        Изменить
                      </v-btn>
                    </with-tooltip>
                  </v-flex>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-card-text>
    </v-card>

    <ConfirmModal
      :dialog.sync="showModal"
      @confirm="handlerConfirmModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>{{ modalTitle }}</template>
      <template v-slot:body>
        <div v-html="modalText"></div>
        <div
          v-show="
            (modalType === modalTypes.giveOutAllOrders ||
              modalType === modalTypes.confirmAllOrders) &&
            ordersTrafficDiff.length
          "
        >
          <br />
          <strong>Внимание!</strong> В следующих классах есть расхождение с
          явкой, отмеченной родителем:
          <ul>
            <li v-for="order in ordersTrafficDiff" :key="order.class.id">
              {{ order.class.name }}
            </li>
          </ul>
        </div>
      </template>
    </ConfirmModal>

    <!-- ОК -->
    <ConfirmModal
      :dialog.sync="giveOutAllOrdersAttentionModal"
      cancelBtnText="ОК"
      :hideConfirmButton="true"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>{{ modalTitle }}</template>
      <template v-slot:body>
        <div v-html="modalText"></div>
      </template>
    </ConfirmModal>

    <!-- Печатать -->
    <ConfirmModal
      :dialog.sync="houseModalShow"
      confirmBtnText="Печатать"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="handlerHouseModal"
    >
      <template v-slot:headline>Печать заявки по корпусу</template>
      <template v-slot:body>
        <v-flex md6>
          <v-select
            no-data-text="Ничего не выбрано"
            v-model="selectedHouseIDs"
            item-text="name"
            item-value="id"
            :items="houses"
            label="Корпуса:"
            deletable-chips
            chips
            multiple
            hide-details
          >
            <template v-slot:prepend-item>
              <v-list-tile
                ripple
                @click="selectAll('selectedHouseIDs', 'houses')"
              >
                <v-list-tile-action>
                  <v-icon
                    :color="selectedHouseIDs.length > 0 ? 'primary' : ''"
                    >{{
                      selectedHouseIDs.length === houses.length
                        ? 'check_box'
                        : 'check_box_outline_blank'
                    }}</v-icon
                  >
                </v-list-tile-action>
                <li>
                  <v-list-tile-title>Выбрать всё</v-list-tile-title>
                  <v-list-tile-sub-title
                    >Выбрано:
                    {{ selectedHouseIDs.length }}</v-list-tile-sub-title
                  >
                </li>
              </v-list-tile>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-flex>
      </template>
    </ConfirmModal>

    <!-- Отменить заказы -->
    <ConfirmModal
      :dialog.sync="cancelOrdersModal"
      confirmBtnText="Отменить заказы"
      :disableConfirmButton="cancelOrdersClasses.length === 0"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="handlerCancelModal"
    >
      <template v-slot:headline>Отмена заказов</template>
      <template v-slot:body>
        <v-flex>
          <v-autocomplete
            v-model="cancelOrdersClasses"
            :title="'Выберите классы'"
            :label="'Выберите классы'"
            clearable
            :items="schoolClasses"
            item-text="name"
            item-value="id"
            multiple
            class="fs-autocomplete"
            height="30"
            :search-input.sync="search"
            @change="
              search = null
              allClassesSelected = false
            "
            hide-details
          >
          </v-autocomplete>
          <v-checkbox
            :label="'Выбрать все'"
            :title="'Выбрать все'"
            v-model="allClassesSelected"
            @change="toggleAllClasses"
          ></v-checkbox>
        </v-flex>
      </template>
    </ConfirmModal>

    <!-- foodModal.type -->
    <ConfirmModal
      :dialog.sync="foodModal.show"
      :confirmBtnText="foodModal.type"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="handleFoodModalConfirm"
    >
      <template v-slot:headline>Выберите классы</template>
      <template v-slot:body>
        <v-flex>
          <v-select
            no-data-text="Ничего не выбрано"
            v-model="selectedClassIds"
            item-text="name"
            item-value="id"
            :items="classes"
            label="Классы:"
            deletable-chips
            chips
            multiple
            hide-details
          >
            <template v-slot:prepend-item>
              <v-list-tile
                ripple
                @click="selectAll('selectedClassIds', 'classes')"
              >
                <v-list-tile-action>
                  <v-icon
                    :color="selectedClassIds.length > 0 ? 'primary' : ''"
                    >{{
                      selectedClassIds.length === classes.length
                        ? 'check_box'
                        : 'check_box_outline_blank'
                    }}</v-icon
                  >
                </v-list-tile-action>
                <li>
                  <v-list-tile-title>Выбрать всё</v-list-tile-title>
                  <v-list-tile-sub-title
                    >Выбрано:
                    {{ selectedClassIds.length }}</v-list-tile-sub-title
                  >
                </li>
              </v-list-tile>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-flex>
      </template>
    </ConfirmModal>

    <!-- Пересоздать черновики -->
    <ConfirmModal
      :dialog.sync="recreateDraftsModal"
      confirmBtnText="Пересоздать черновики"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="checkRecreateDraftsConfirm"
      :disableConfirmButton="!selectedClassIds.length"
    >
      <template v-slot:headline>Выберите классы</template>
      <template v-slot:body>
        <v-flex>
          <strong
            >Данное действие запустит процесс пересоздания заказов в статусе
            Черновик.</strong
          ><br /><br />
          <v-select
            no-data-text="Ничего не выбрано"
            v-model="selectedClassIds"
            item-text="name"
            item-value="id"
            :items="sortClasses"
            label="Классы:"
            deletable-chips
            chips
            multiple
            hide-details
          >
            <template v-slot:prepend-item>
              <v-list-tile
                ripple
                @click="selectAll('selectedClassIds', 'classes')"
              >
                <v-list-tile-action>
                  <v-icon
                    :color="selectedClassIds.length > 0 ? 'primary' : ''"
                    >{{
                      selectedClassIds.length === classes.length
                        ? 'check_box'
                        : 'check_box_outline_blank'
                    }}</v-icon
                  >
                </v-list-tile-action>
                <li>
                  <v-list-tile-title>Выбрать всё</v-list-tile-title>
                  <v-list-tile-sub-title
                    >Выбрано:
                    {{ selectedClassIds.length }}</v-list-tile-sub-title
                  >
                </li>
              </v-list-tile>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-flex>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import moment from 'moment'
import {
  cancelOrders,
  changeOrdersStatus,
  chargeMoney,
  getChangedOrders,
  getClasses,
  getOrders,
  getOrdersByHouse,
  getPrintOrderFile,
  getPrintOrderStatus,
  getPrintOrderTaskId,
  getSchoolHouse,
  getSettings,
  getTaskId,
  getTaskResults,
  getTaskStatus,
  printMenu,
  printOrder,
  recreateDraftsSchool,
  setCancelOrderStatus,
  setConfirmedStatus,
  setDryFoodType,
  setSubsidyFoodType,
  transferTrafficSchool,
} from '@/router/routes/admin'

import {
  departmentPrintMenu,
  getClasses as getClassesDepartment,
  getOrders as getOrdersDepartment,
  getOrdersByHouse as getOrdersByHouseDepartment,
  getPrintOrderFileDepartment,
  getPrintOrderStatusDepartment,
  getPrintOrderTaskIdDepartment,
  getSchoolHouseByDepartment,
  printOrder as printOrderDepartment,
} from '@/router/routes/department'

import { mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import _ from 'lodash'
import { ConfirmModal } from '@/components/common'
import WithTooltip from '@/components/common/Tooltip/Tooltip'
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal'
import ElementGroup from '@/components/common/ElementGroup/ElementGroup'
import notify from '@/utils/notifications/toastNotification'
import asyncReqests from '@/helpers/asyncRequestWithProgressBar'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownloadWithProgressBar'
import OrderCancelModal from '@/components/admin/Orders/Components/OrderCancelModal.vue'

export default {
  name: 'AdminMealsOrdersView',
  components: {
    OrderCancelModal,
    ElementGroup,
    ContextHelpModal,
    WithTooltip,
    ConfirmModal
  },
  props: {
    municipalitySchoolId: {
      type: Number,
      required: false,
      default: null
    },
    municipalitySchoolName: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      workingDaysInOrders: store.getters.setting('show_working_days_in_orders'),
      isShowFeedingCount: false,
      contextKeys: Vue.config.contextHelp,
      title: 'Заявки на питание',
      isEjSettings: false,
      pagination: {
        sortBy: 'class.number',
        descending: false,
        rowsPerPage: -1
      },
      headers: [
        { text: 'Дата и время', value: 'date' },
        { text: 'Класс', value: 'class.name' },
        { text: 'Статус', value: 'status' },
        { text: 'Инфо', value: 'ordersErrors', width: '1', sortable: false },
        { text: 'Операции', value: 'operations', sortable: false }
      ],
      allow_action: true,
      items: [],
      showModal: false,
      modalTitle: '',
      modalText: '',
      modalTypes: {
        giveOutAllOrders: 0,
        transferTrafficSchool: 1,
        confirmAllOrders: 2,
        confirmCancelOrders: 3,
        chargeMoney: 4
      },
      giveOutAllOrdersAttentionModal: false,
      cancelOrdersModal: false,
      cancelOrdersClasses: [],
      modalType: null,
      day: null,
      month: null,
      year: null,
      schoolName: null,
      savedFilters: null,
      houses: [],
      currentHouseId: null,
      selectedHouseIDs: [],
      houseModalShow: false,
      orderDate: null,
      showGiveOutAllOrdersBtn: false,
      datepickerMenu: false,
      date: '',
      search: null,
      allClassesSelected: false,
      isCanBeChargedOrder: false,
      foodModal: {
        show: false,
        type: null,
        method: null
      },
      selectedClassIds: [],
      classes: [],
      reportName: 'Табель питания',
      printCallback: () => {},
      recreateDraftsModal: false,
      infoModal: true,

      serverDateFormat: Vue.config.format.serverDate,
      viewDataFormat: Vue.config.format.pythonDate
    }
  },

  // COMPUTED
  computed: {
    ...mapGetters([ 'currentUser', 'setting' ]),

    /**
     * Определяет, находится ли пользователь в режиме просмотра муниципалитета
     * @returns {boolean} - Возвращает true, если свойство meta.isMunicipality установлено в true, иначе false
     */
    municipalityView() {
      return this.$route.meta.hasOwnProperty('isMunicipality')
    },

    reportsNavBar() {
      return {
        preOrder: {
          tooltip:
            "Информация по заявкам до момента, когда заявки были переведены в статус 'Подтверждено школой'",
          handler: this.printOrdersHandler,
          title: 'Заявка предварительная'
        },
        actualOrder: {
          tooltip: 'Информация по заявкам на текущий момент',
          handler: this.printActualOrdersHandler,
          title: 'Заявка фактическая'
        },
        pupilsOrder: {
          tooltip: 'Информация о заказанных комплексах по ученикам',
          handler: this.printOrdersPupilsHandler,
          title: 'Заявка по учащимся'
        },
        complexesOrder: {
          tooltip: 'Информация о заявках в разрезе комплексов питания',
          handler: () => this.printSchool('form2'),
          title: 'Заявка по комплексам'
        },
        menuOfTheDay: {
          tooltip: 'Информация о меню на выбранную дату',
          handler: () => this.print('menu'),
          title: 'Меню на день'
        }
      }
    },

    navBarForWorkingWithOrders() {
      return {
        cancelOrder: {
          tooltip: `При нажатии будет предложено выбрать классы,
            заявки в которых необходимо отменить.
            Отмена будет произведена для заявок в статусах:
            Черновик, Подтверждено учителем, подтверждено школой, Изменен.`,
          handler: () => (this.cancelOrdersModal = true),
          title: 'Отменить заказы',
          icon: 'no_meals', // Иконка для отмены заказов
          iconColor: this.darkTheme ? 'white' : 'error',
          show:
            !this.municipalityView &&
            this.showCancelAllOrdersBtn &&
            !this.setting('admin_cancel_orders')
        },
        cancelSchoolOrdersForDay: {
          tooltip: `При нажатии будет произведена отмена всех заявок
           по школе находящихся в статусе 'Выдан'`,
          handler: () => this.modalHandler(this.modalTypes.confirmCancelOrders),
          title: 'Отменить заказы школы за день',
          icon: 'no_meals', // Иконка для отмены заказов по школе
          iconColor: this.darkTheme ? 'white' : 'error',
          show: !this.setting('admin_cancel_orders')
        },
        giveOutOrders: {
          tooltip: `При нажатии заказы будут переведены в статус
	          'Выдано' для подтвержденных заявок,
	          либо в 'Выполнены' для заявок 'Выполнено (без списания)'`,
          handler: () => this.modalHandler(this.modalTypes.giveOutAllOrders),
          title: 'Выдать заказы',
          icon: 'restaurant', // Иконка для выдачи заказов
          iconColor: this.darkTheme ? 'white' : 'green',
          show:
            !this.municipalityView &&
            this.showGiveOutAllOrdersBtn &&
            this.showGiveOutAllOrdersBtnFromInputDate
        },
        chargeMoney: {
          tooltip: `При нажатии заказы будут переведены 'Выполнены'
           для заявок 'Выполнено (без списания)`,
          handler: () => this.modalHandler(this.modalTypes.chargeMoney),
          title: 'Выполнить списание',
          icon: 'restaurant', // Иконка для выполнения заказов
          iconColor: this.darkTheme ? 'white' : 'orange',
          show:
            this.setting('charge_money') &&
            this.isCanBeChargedOrder &&
            !this.municipalityView
        },
        confirmAllOrders: {
          tooltip: `При нажатии заказы будут переведены в статус 'Подтверждено школой'`,
          handler: () => this.modalHandler(this.modalTypes.confirmAllOrders),
          title: 'Подтвердить заказы',
          icon: 'thumb_up_alt', // Иконка для подтверждения заказов
          iconColor: this.darkTheme ? 'white' : 'green',
          show: !this.municipalityView && this.showConfirmAllOrdersBtn
        },
        recreateDrafts: {
          tooltip: `При нажатии будет предложено выбрать классы,
           в которых необходимо пересоздать заявки.
           Имеющиеся черновики за выбранную дату будут удалены
           и для всех учеников в классе система попробует пересоздать заявки.`,
          handler: () => (this.recreateDraftsModal = true),
          title: 'Пересоздать черновики',
          icon: 'autorenew', // Иконка для пересоздания черновиков
          iconColor: this.darkTheme ? 'white' : 'primary',
          show: this.showRecreateDraftsButton
        },
        transferTrafficToNextDay: {
          tooltip: `Переносит явку аналогично текущему дню на заявки следующего дня`,
          handler: () =>
            this.modalHandler(this.modalTypes.transferTrafficSchool),
          title: 'Перенести явку на следующий день',
          icon: 'copy_all', // Иконка для переноса явки
          iconColor: this.darkTheme ? 'white' : 'primary',
          show: !this.municipalityView
        },
        fillAttendanceByEJ: {
          tooltip: `Переносит явку на заказы согласно данным ЭЖ`,
          handler: () => this.fillByEJournal(),
          title: 'Заполнить явку по ЭЖ',
          icon: 'cloud_sync', // Иконка для заполнения явки по ЭЖ
          iconColor: this.darkTheme ? 'white' : 'primary',
          show: this.isEjSettings && !this.municipalityView
        },
        setDryFood: {
          tooltip: `Переводит все заявки на тип питания 'Сух. паек'`,
          handler: () => this.setDryFood(),
          title: 'Поставить на сух. паек',
          icon: 'bento', // Иконка для установки сухого пайка
          iconColor: this.darkTheme ? 'white' : 'orange',
          show: !this.municipalityView
        },
        setSubsidy: {
          tooltip: `Переводит все заявки на бюджетный тип питания (если доступен)`,
          handler: () => this.setSubsidy(),
          title: 'Поставить на дотацию',
          icon: 'money_off', // Иконка для установки дотации
          iconColor: this.darkTheme ? 'white' : 'orange',
          show: !this.municipalityView
        }
      }
    },

    schoolId: {
      get() {
        if (this.municipalityView) {
          return this.municipalitySchoolId
        }
        return this.currentUser.school.id
      },
      set(value) {
        return value
      }
    },

    sortClasses() {
      return this.classes
        .sort((a, b) =>
          this.getClassLiter(a.name).localeCompare(this.getClassLiter(b.name))
        )
        .sort((a, b) => a.number - b.number)
    },

    dateNormal() {
      return this.day + '-' + this.month + '-' + this.year
    },

    dateServer() {
      return this.year + '-' + this.month + '-' + this.day
    },

    showGiveOutAllOrdersBtnFromInputDate() {
      return moment(this.dateServer, this.serverDateFormat).isSame(
        moment().format(this.serverDateFormat)
      )
    },

    /**
     * Проверяет, является ли дата заказа сегодняшней или будущей.
     * @returns {boolean} Возвращает `true`, если дата заказа — сегодня или позже,
     * и `false`, если дата заказа — в прошлом.
     */
    isOrderDateTodayOrFuture() {
      const now = moment().startOf('day').unix()
      const orderDate = moment(this.dateServer).startOf('day').unix()
      return orderDate >= now
    },

    /**
     * Проверяет, следует ли отображать кнопку подтверждения всех заказов.
     * @returns {boolean} Возвращает `true`, если дата заказа сегодняшняя или будущая
     * и хотя бы один из заказов имеет статус, допустимый для подтверждения.
     */
    showConfirmAllOrdersBtn() {
      const confirmStatuses = [
        Vue.config.general.orderStatuses.draft,
        Vue.config.general.orderStatuses.teacherSaved,
        Vue.config.general.orderStatuses.teacherConfirm,
        Vue.config.general.orderStatuses.schoolSaved,
        Vue.config.general.orderStatuses.confirmAuto
      ]

      return (
        this.isOrderDateTodayOrFuture &&
        this.hasOrderWithStatus(this.items, confirmStatuses)
      )
    },

    /**
     * Проверяет, следует ли отображать кнопку отмены всех заказов.
     * @returns {boolean} Возвращает `true`, если дата заказа сегодняшняя или будущая
     * и хотя бы один из заказов имеет статус, допустимый для отмены.
     */
    showCancelAllOrdersBtn() {
      const cancelStatuses = [
        Vue.config.general.orderStatuses.draft,
        Vue.config.general.orderStatuses.teacherSaved,
        Vue.config.general.orderStatuses.teacherConfirm,
        Vue.config.general.orderStatuses.schoolSaved,
        Vue.config.general.orderStatuses.confirmAuto,
        Vue.config.general.orderStatuses.schoolConfirm,
        Vue.config.general.orderStatuses.updated
      ]

      return (
        this.isOrderDateTodayOrFuture &&
        this.hasOrderWithStatus(this.items, cancelStatuses)
      )
    },

    showRecreateDraftsButton() {
      return this.isOrderDateTodayOrFuture
    },

    schoolClasses() {
      return this.items
        .map((item) => ({
          id: item.class.id,
          name: item.class.name,
          number: item.class.number
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => a.number - b.number)
    },

    ordersTrafficDiff() {
      return this.items.filter((item) => item.traffic_diff)
    },

    viewData: {
      get() {
        return moment(this.dateServer).format(this.viewDataFormat)
      },
      set(value) {
        return moment(value).format(this.viewDataFormat)
      }
    },

    getIsShowFeedingCount: {
      get() {
        return this.isShowFeedingCount
      },
      set(value) {
        this.isShowFeedingCount = value
      }
    },

    isInCurrentLearnYearOrLater() {
      const selectDate = moment(this.viewData, this.viewDataFormat)
      const currentDate = moment().hour(0).minute(0).second(0)
      const separationDate = moment()
        .month(6) // принимаются от 0 до 11
        .date(15) // принимаются от 1 до 31
        .hour(0)
        .minute(0)
        .second(0)
      const secondHalfYear = currentDate.isBefore(separationDate)
      const prevYear = separationDate.clone().subtract(1, 'year')
      return secondHalfYear
        ? selectDate.isBetween(prevYear, separationDate)
        : selectDate.isAfter(separationDate)
    },

    relevantOrdersCount() {
      /* Количество актуальных заявок (отменённые не подсчитываются) */
      /* Итоговое кол-во питающихся за выбранную дату во всех классах. */
      return this.items.reduce((acc, { orders_total }) => acc + orders_total, 0)
    }
  },

  // MOUNTED
  mounted() {
    this.getHeaders()
    const value = localStorage.getItem('infoModal')
    if (value === null) {
      localStorage.setItem('infoModal', 'true')
    } else {
      value === 'true' ? (this.infoModal = true) : (this.infoModal = false)
    }
  },

  created() {
    // this.schoolId = _.get(
    //   this.currentUser,
    //   'school.id',
    //   this.municipalityView ? this.municipalitySchoolId : null
    // )
    this.schoolName = _.get(
      this.currentUser,
      'school.short_name',
      this.municipalityView ? this.municipalitySchoolName : null
    )
    const { day, month, year } = this.$route.params
    if (day && month && year) {
      this.day = day
      this.month = month
      this.year = year
      this.date = year + '-' + month + '-' + day
    } else {
      this.setDateNow()
    }
    if (this.$route.params.houseId) {
      this.currentHouseId = this.$route.params.houseId
    }

    this.loadOrders()
    this.fetchSchoolHouse()

    const method = this.municipalityView ? getClassesDepartment : getClasses

    method({ schoolId: this.schoolId }).then((response) => {
      this.classes = response.data.data.map((item) => {
        const { id, name, number } = item
        return {
          id: id,
          name: name,
          number: number
        }
      })
    })
  },

  // METHODS
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),

    getHeaders() {
      if (this.workingDaysInOrders) {
        this.headers.splice(3, 0, {
          text: 'Рабочий день',
          value: 'is_working_day',
          width: '1%',
          sortable: false
        })
      }
    },

    /**
     * Проверяет, есть ли в списке элементов элемент с указанными статусами.
     * @param {Array} items - Массив элементов заказов.
     * @param {Array} statuses - Массив допустимых статусов.
     * @returns {boolean} Возвращает `true`, если хотя бы один элемент имеет указанный статус,
     * и `false` в противном случае.
     */
    hasOrderWithStatus(items, statuses) {
      const statusSet = new Set(statuses)
      return items.some((item) => statusSet.has(item.status))
    },

    async fetchSchoolHouse() {
      try {
        const payload = {
          school_id: this.schoolId
        }
        if (this.municipalityView) {
          payload.department_id = this.currentUser.department.id
          payload.school_id = this.municipalitySchoolId
        }

        const { data } = this.municipalityView
          ? await getSchoolHouseByDepartment(payload)
          : await getSchoolHouse(payload)
        this.houses = data
      } catch (e) {
        notify.error('Ошибка загрузки')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    closeInfoModal() {
      this.infoModal = false
      localStorage.setItem('infoModal', 'false')
    },
    getClassLiter(name) {
      return name.split(' ')[1]
    },

    async getSettings() {
      try {
        const PAYLOAD = {
          school_id: this.schoolId
        }
        const {
          data: { data }
        } = await getSettings(PAYLOAD)
        this.isEjSettings = data.fillMealCardEj
        // PM-898 временно убираем
        // this.mealOrderSubmissionTime = response.data.data.mealOrderSubmissionTime
      } catch (error) {
        notify.error('Ошибка загрузки настроек')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    setDateNow() {
      this.day = moment().date()
      this.month = moment().month() + 1
      this.year = moment().year()
      this.date = moment().format(this.serverDateFormat)
    },

    async changeSchoolOrdersStatus(action) {
      if (!this.allow_action) return
      this.allow_action = false
      const payload = {
        schoolClassId: this.classes.map((i) => i.id),
        action: action,
        orderDay: this.date
      }
      try {
        await changeOrdersStatus(payload)
      } catch (error) {
      } finally {
        this.allow_action = true
        store.commit('setShowPreloader', false)
        await this.loadOrders()
      }
      changeOrdersStatus(payload)
        .then(() => {})
        .finally(() => store.commit('setShowPreloader', false))
    },
    setDryFood() {
      this.selectedClassIds = []
      this.foodModal.type = 'Поставить на сух. паек'
      this.foodModal.method = setDryFoodType
      this.foodModal.show = true
    },
    setSubsidy() {
      this.selectedClassIds = []
      this.foodModal.type = 'Поставить на дотацию'
      this.foodModal.method = setSubsidyFoodType
      this.foodModal.show = true
    },
    async checkRecreateDraftsConfirm(value) {
      if (!this.selectedClassIds.length) {
        return
      }
      if (!value) {
        return
      }
      let ClickTimeRecreateDrafts
      try {
        ClickTimeRecreateDrafts = JSON.parse(
          localStorage.getItem('lastClickTimeRecreateDrafts')
        )
      } catch (err) {
        ClickTimeRecreateDrafts = new Date().getTime() - 1000000
      }
      const timeElapsed = new Date().getTime() - ClickTimeRecreateDrafts
      if (timeElapsed > 300000) {
        localStorage.setItem('recreateClassIds', JSON.stringify([]))
      }
      if (
        JSON.parse(localStorage.getItem('recreateClassIds')).some((item) =>
          this.selectedClassIds.includes(item)
        )
      ) {
        notify.error(
          `Повторное пересоздание заявок возможно через ${this.recountMinutes(
            timeElapsed
          )} мин. ${this.recountSeconds(timeElapsed)} сек.`
        )
        return
      }
      this.handleRecreateDraftsConfirm()
    },

    recountMinutes(timeElapsed) {
      return Math.floor((300000 - timeElapsed) / 60000)
    },
    recountSeconds(timeElapsed) {
      return Math.floor(((300000 - timeElapsed) % 60000) / 1000)
    },

    async handleRecreateDraftsConfirm() {
      try {
        const payload = {
          schoolId: this.schoolId,
          date: this.date,
          data: {
            classIds: this.selectedClassIds
          }
        }
        let recreateClassIds
        try {
          recreateClassIds = JSON.parse(
            localStorage.getItem('recreateClassIds')
          )
        } catch (err) {
          recreateClassIds = []
        }
        recreateClassIds = Array.from(
          new Set(recreateClassIds.concat(this.selectedClassIds))
        )
        localStorage.setItem(
          'recreateClassIds',
          JSON.stringify(recreateClassIds)
        )
        localStorage.setItem(
          'lastClickTimeRecreateDrafts',
          new Date().getTime()
        )
        await recreateDraftsSchool(payload)
      } catch (error) {
        notify.error('Не удалось пересоздать заказы.')
      } finally {
        store.commit('setShowPreloader', false)
        this.recreateDraftsModal = false
        await this.loadOrders()
      }
    },

    async handleFoodModalConfirm() {
      if (!this.selectedClassIds.length) {
        return
      }
      this.foodModal
        .method(this.schoolId, this.date, this.selectedClassIds)
        .then(() => {
          notify.success('Смена типов питания выполнена')
          store.commit('setShowPreloader', false)
        })
    },

    selectDate() {
      this.datepickerMenu = false
      this.day = moment(this.date).date()
      this.month = moment(this.date).month() + 1
      this.year = moment(this.date).year()
    },

    async changeValue(type, value) {
      this[type] = value
    },

    async handlerSearch() {
      this.resetData()
      await this.loadOrders()
      store.commit('setShowPreloader', false)
    },

    selectAll(selectArray, parentArray) {
      this.$nextTick(() => {
        if (this[selectArray].length) {
          this[selectArray] = []
        } else {
          const idsArray = this[parentArray].map((item) => item.id)
          this[selectArray] = [ ...idsArray ]
        }
      })
    },

    resetData() {
      this.items = []
    },

    async loadOrders() {
      const payload = {
        id: this.schoolId,
        houseId: this.currentHouseId,
        date: this.dateServer
      }
      const methodGetOrders = this.municipalityView
        ? getOrdersDepartment
        : getOrders
      const methodGetOrderByHouse = this.municipalityView
        ? getOrdersByHouseDepartment
        : getOrdersByHouse

      try {
        const { data } = this.currentHouseId
          ? await methodGetOrderByHouse(payload)
          : await methodGetOrders(payload)

        const orders = data

        this.setChargeMoneyButtonStatus(orders)

        this.items = _.map(orders, (item) => {
          return {
            date: payload.date,
            class: {
              id: _.get(item, 'id', null),
              name: _.get(item, 'name', null),
              number: _.get(item, 'number', null),
              house: _.get(item, 'house', null)
            },
            status: _.get(item, 'status', null),
            statusName: this.getStatusName(
              _.get(item, 'status', null),
              _.get(item, 'status_name', null),
              _.get(item, 'status_received_is_auto', null)
            ),
            traffic_diff: item.traffic_diff,
            is_working_day: item.is_working_day,
            ordersErrors: item.order_error,
            orders_total: item?.orders_total || 0
          }
        })
      } catch (error) {
        notify.error('Не удалось загрузить заявки')
      } finally {
        this.setGiveOutAllOrdersBtnStatus(this.dateServer)
        store.commit('setShowPreloader', false)
      }
      if (!this.municipalityView) {
        this.getSettings().then(() => store.commit('setShowPreloader', false))
      }
    },

    getStatusName(status, name, status_received) {
      if (status === 5 && status_received) {
        return `${name} (без списания)`
      }
      return name
    },

    async callbackFillByEJournal(taskID) {
      try {
        await getTaskResults({
          task_id: taskID
        })
        await this.handlerSearch()
        notify.success('Заполнение явки по ЭЖ прошло успешно')
      } catch (e) {
        if (e.response.status === 404) {
          notify.error(e.response.data.data.message)
        } else {
          notify.error('Ошибка заполнения журнала')
        }
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async fillByEJournal() {
      const payload = {
        day: this.day,
        date: this.date,
        school_id: this.schoolId
      }

      try {
        await asyncReqests(
          getTaskId,
          getTaskStatus,
          payload,
          this.callbackFillByEJournal
        )
      } catch (error) {
        notify.error('Ошибка заполнения журнала')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async printSchool(form_type) {
      try {
        const PAYLOAD = {
          order_day: this.dateServer,
          schoolId: this.schoolId
        }
        PAYLOAD.reportType = form_type || 'newForm'
        const method = this.municipalityView ? printOrderDepartment : printOrder
        const response = await method(PAYLOAD)
        const file = new Blob([ response.data ], {
          type: response.data.type
        })
        const url = window.URL.createObjectURL(file)
        const a = document.createElement('a')
        a.href = url
        a.download =
          `Табель заявок по школе - ${this.schoolName} - ` +
          this.dateNormal +
          '.xlsx'
        a.click()
      } catch (error) {
        notify.error('Ошибка загрузки отчёта')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    printOrdersHandler() {
      this.reportName = 'Табель питания предварительный'
      if (this.houses.length) {
        this.printCallback = this.printTurnout
        this.houseModalShow = true
      } else {
        this.printTurnout('newForm')
      }
    },

    printActualOrdersHandler() {
      this.reportName = 'Табель питания фактический'
      if (this.houses.length) {
        this.printCallback = this.printActual
        this.houseModalShow = true
      } else {
        this.printActual()
      }
    },

    // Прослойка для кнопки заявка по учищамся
    printOrdersPupilsHandler() {
      this.reportName = 'Заявка по учищимся'
      if (this.houses.length) {
        this.printCallback = this.printOrders
        this.houseModalShow = true
      } else {
        this.printOrders()
      }
    },

    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    },

    // Кнопка Заявка по учащимся
    async printOrders() {
      await this.printTurnout('newForm')
    },

    async printActual() {
      await this.printTurnout('withChanges')
    },

    async printTurnout(reportType = 'oldForm') {
      if ([ 'withChanges', 'newForm', 'oldForm' ].includes(reportType)) {
        const PAYLOAD = {
          reportType: reportType,
          school_id: this.schoolId,
          order_day: this.dateServer
        }
        if (this.selectedHouseIDs.length && this.selectedHouseIDs.length !== this.houses.length) {
          PAYLOAD.house_id = [ ...this.selectedHouseIDs ]
        }
        const callback = this.resetPageFileLoaded
        const method1 = this.municipalityView
          ? getPrintOrderTaskIdDepartment
          : getPrintOrderTaskId
        const method2 = this.municipalityView
          ? getPrintOrderStatusDepartment
          : getPrintOrderStatus
        const method3 = this.municipalityView
          ? getPrintOrderFileDepartment
          : getPrintOrderFile
        try {
          await asyncReqestsForDownload(
            method1,
            method2,
            method3,
            PAYLOAD,
            callback
          )
        } catch (error) {
          notify.error('Ошибка загрузки табеля')
        } finally {
          store.commit('setShowPreloader', false)
        }
      }
    },

    async print(reportType) {
      if (reportType === 'menu') {
        const payload = {
          schoolId: this.schoolId,
          date: this.date
        }
        try {
          const response = this.municipalityView
            ? await departmentPrintMenu(payload)
            : await printMenu(payload)
          const file = new Blob([ response.data ], {
            type: response.data.type
          })
          const url = window.URL.createObjectURL(file)
          const a = document.createElement('a')
          a.href = url
          a.download = this.reportName + ' ' + this.dateNormal + '.xlsx'
          a.click()
        } catch (error) {
          notify.error('Ошибка загрузки меню')
        } finally {
          store.commit('setShowPreloader', false)
        }
        return
      }

      try {
        const PAYLOAD = {
          order_day: this.dateServer,
          schoolId: this.schoolId
        }
        if (reportType) {
          PAYLOAD.reportType = reportType
        }
	      if (this.selectedHouseIDs.length && this.selectedHouseIDs.length !== this.houses.length) {
          PAYLOAD.house_id = [ ...this.selectedHouseIDs ]
        }
        const method = this.municipalityView ? printOrderDepartment : printOrder
        const response = await method(PAYLOAD)
        const file = new Blob([ response.data ], {
          type: response.data.type
        })
        const url = window.URL.createObjectURL(file)
        const a = document.createElement('a')
        a.href = url
        a.download = this.reportName + ' ' + this.dateNormal + '.xlsx'
        a.click()
      } catch (error) {
        notify.error('Ошибка загрузки табеля')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    handlerHouseModal(value) {
      if (value) {
        if (this.selectedHouseIDs.length) {
          this.printCallback()
        } else {
          notify.error('Ошибка печати. Не выбран корпус')
        }
      }
    },

    navigateTo(where, params, houseId) {
      const data = {
        date: moment(params.date).format(this.serverDateFormat),
        schoolId: this.schoolId,
        classId: params.class.id,
        className: params.class.name,
        houseId,
        day: this.day,
        year: this.year,
        month: this.month
      }

      data.id = data.classId

      this.$router.push({ name: where, params: data })
    },

    async giveOutAllOrdersRequest() {
      try {
        const PAYLOAD = {
          schoolId: this.schoolId,
          date: this.date
        }
        await getChangedOrders(PAYLOAD)
        notify.success(`Заказы успешно выданы`)
      } catch (error) {
        notify.error('Ошибка выдачи заказов')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async setCancelOrderStatus() {
      try {
        const PAYLOAD = {
          schoolId: this.schoolId,
          date: this.date
        }
        const { data } = await setCancelOrderStatus(PAYLOAD)
        await this.loadOrders()
        if (data.data.length === 0) {
          notify.info(`Отсутствуют заказы подходящие для отмены`)
        } else {
          notify.success(`Выданные заказы отменены`)
        }
      } catch (error) {
        notify.error('Ошибка отмены заказов')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async setConfirmedStatus() {
      try {
        const PAYLOAD = {
          schoolId: this.schoolId,
          date: this.date
        }
        await setConfirmedStatus(PAYLOAD)
        await this.loadOrders()
        notify.success(`Заказы успешно подтверждены`)
      } catch (error) {
        notify.error('Ошибка подтверждения заказов')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async transferTrafficSchoolRequest() {
      try {
        const PAYLOAD = {
          schoolId: this.schoolId,
          date: this.date
        }
        const response = await transferTrafficSchool(PAYLOAD)
        const workingDay = 'следующий рабочий день'
        if (response.data.success) {
          notify.success(
            `Явка учеников по школе успешно перенесена на ${workingDay}`
          )
        }
      } catch (error) {
        notify.error('Ошибка переноса явки учеников.')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async handlerConfirmModal(value) {
      if (value) {
        switch (this.modalType) {
        case this.modalTypes.giveOutAllOrders: {
          await this.giveOutAllOrdersRequest()
          break
        }
        case this.modalTypes.transferTrafficSchool: {
          await this.transferTrafficSchoolRequest()
          break
        }
        case this.modalTypes.confirmAllOrders: {
          await this.setConfirmedStatus()
          break
        }
        case this.modalTypes.confirmCancelOrders: {
          await this.setCancelOrderStatus()
          break
        }
        default:
          break
        case this.modalTypes.chargeMoney: {
          await this.chargeMoneyRequest()
          break
        }
        }
      }
    },

    async modalHandler(type) {
      switch (type) {
      case this.modalTypes.giveOutAllOrders: {
        const now = moment()._d
        const hours = new Date(now).getHours()
        // if (hours < 8 || hours > 21) {
        //   this.giveOutAllOrdersAttentionModal = true
        //   this.modalTitle = `Внимание`
        //   this.modalText = `Данной кнопкой выдать заказы можно с 08:00 до 22:00`
        //   return
        // }
        this.modalType = this.modalTypes.giveOutAllOrders
        this.modalTitle = `Подтверждение выдачи всех заказов`
        this.modalText = `
          Вы подтверждаете выдачу всех заказов по всей школе?<br />
          <b>Внимание</b> - в статус <b>заказ выдан</b> переведутся только заявки в статусе
          <b>заказ подтвержден школой</b>
          статус заявок обновится <b>после повторного</b> открытия вкладки "Заявки на питание"
        `
        break
      }
      case this.modalTypes.transferTrafficSchool: {
        this.modalType = this.modalTypes.transferTrafficSchool
        this.modalTitle = `Подтверждение переноса явки по школе`
        this.modalText = `
          Вы подтверждаете <b>перенос явки</b> всех классов
          <b>по всей школе</b> на следующий рабочий день?
        `
        break
      }
      case this.modalTypes.confirmAllOrders: {
        this.modalType = this.modalTypes.confirmAllOrders
        this.modalTitle = 'Подтверждение выдачи заказов'
        this.modalText =
            'Выполнить <b>подтверждение заказов</b> всех классов <b>по всей школе</b>?'
        break
      }
      case this.modalTypes.confirmCancelOrders: {
        this.modalType = this.modalTypes.confirmCancelOrders
        this.modalTitle = 'Подтверждение отмены заказов'
        this.modalText =
            'Выполнить <b>отмену заказов</b> всех классов <b>по всей школе</b> за выбранный день?'
        break
      }
      case this.modalTypes.chargeMoney: {
        this.modalType = this.modalTypes.chargeMoney
        this.modalTitle = `Подтверждение списания заказов`
        this.modalText = `Вы подтверждаете списание заказов на ${this.dateNormal}?`
        break
      }

      default:
        break
      }
      this.showModal = true
    },

    // TODO Переписать
    setGiveOutAllOrdersBtnStatus() {
      const orderDate = moment(this.dateServer, this.serverDateFormat)
      const now = moment().format(this.serverDateFormat)
      this.showGiveOutAllOrdersBtn = orderDate.isSame(now)
    },

    customSort(items, index, isDesc) {
      switch (index) {
      case 'class.name': {
        if (isDesc) {
          return items
            .sort((a, b) => a.class.name.localeCompare(b.class.name))
            .sort((a, b) => a.class.number - b.class.number)
        }
        return items
          .sort((a, b) => b.class.name.localeCompare(a.class.name))
          .sort((a, b) => b.class.number - a.class.number)
      }
      case 'status': {
        if (isDesc) {
          return items.sort((a, b) => a.status - b.status)
        }
        return items.sort((a, b) => b.status - a.status)
      }
      case 'date': {
        if (isDesc) {
          return items.sort((a, b) => a.date.localeCompare(b.date))
        }
        return items.sort((a, b) => b.date.localeCompare(a.date))
      }
      default:
        return items
          .sort((a, b) => a.class.name.localeCompare(b.class.name))
          .sort((a, b) => a.class.number - b.class.number)
      }
    },

    toggleAllClasses() {
      if (this.allClassesSelected) {
        this.cancelOrdersClasses = [
          ...this.schoolClasses.map((item) => item.id)
        ]
        this.allClassesSelected = true
        return
      }
      this.resetCancelOrders()
    },

    async handlerCancelModal(confirm) {
      if (!confirm) {
        this.resetCancelOrders()
        return
      }
      try {
        const PAYLOAD = {
          id: this.schoolId,
          date: this.date,
          data: { classIds: this.cancelOrdersClasses }
        }
        await cancelOrders(PAYLOAD)
      } catch (error) {
        notify.error('Не удалось отменить заказы')
      } finally {
        this.resetCancelOrders()
        await this.loadOrders()
      }
    },

    async chargeMoneyRequest() {
      try {
        const PAYLOAD = {
          schoolId: this.schoolId,
          date: this.dateServer,
          notVisitedIds: store.getters.notVisitedIds
        }

        await chargeMoney(PAYLOAD)
        notify.success(`Списание заказов успешно выполнено`)
      } catch (error) {
        // notify.error('Ошибка выполнения списания заказов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    setChargeMoneyButtonStatus(orders) {
      this.isCanBeChargedOrder =
        orders.some((item) => item.status_received_is_auto) &&
        this.isChargeMoneyDay()
    },

    isChargeMoneyDay() {
      const dayNow = new Date()
      const orderDate = new Date(this.dateServer)
      const isChargeDay = Math.floor(
        Math.abs(dayNow.getTime() - orderDate.getTime()) / (1000 * 3600 * 24)
      )
      return isChargeDay < 7
    },

    resetCancelOrders() {
      this.cancelOrdersClasses = []
      this.allClassesSelected = false
    },

    selectHouse() {
      this.loadOrders()
    }
  }
}
</script>

<style>
.v-input__slot {
  padding-right: 5px !important;
}
</style>
