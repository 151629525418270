<template>
  <v-container fluid class="fs-teacher-container">
    <v-layout mb-3 column align-start>
      <div class="d-flex row justify-space-between mb-3">
        <h1>{{ title }}</h1>
        <context-help-modal
          :context-key="contextKeys.admin.orders.statusInfo"
        />
      </div>
      <div class="mb-1">
        <with-tooltip text="Вернуться к заявкам на питание">
          <v-btn
            flat
            class="fs-btn fs-btn--secondary fs-admin-btn__cancel v-btn--active"
            @click="handleBack"
            >Назад</v-btn
          >
        </with-tooltip>
      </div>
    </v-layout>
    <!-- reportsNavBar -->
    <v-layout row>
      <div class="d-flex" style="flex-direction: column">
        <element-group title="Отчеты" class="mr-2">
          <ul>
            <li v-for="(v, k) in reportsNavBar" :key="k">
              <with-tooltip :text="v.tooltip">
                <v-btn
                  @click="v.handler"
                  class="fs-btn fs-teacher-btn__excel mr-3"
                >
                  {{ v.title }}
                </v-btn>
              </with-tooltip>
            </li>
          </ul>
        </element-group>
      </div>

      <!-- navBarForWorkingWithOrders -->
      <template v-if="isInCurrentLearnYearOrLater">
        <div v-show="!isViewComponent">
          <element-group title="Работа с заявкой">
            <ul>
              <li v-for="(v, k) in navBarForWorkingWithOrders" :key="k">
                <div>
                  <with-tooltip :text="v.tooltip">
                    <v-btn
                      v-show="v.show"
                      @click="v.handler"
                      class="fs-btn mr-3"
                      :class="v.class"
                    >
                      <v-icon
                        v-if="v.icon"
                        small
                        class="pr-1"
                        :color="v.iconColor || 'white'"
                      >
                        {{ v.icon }}
                      </v-icon>
                      {{ v.title }}
                    </v-btn>
                  </with-tooltip>
                </div>
              </li>
            </ul>
          </element-group>
        </div>
      </template>
    </v-layout>
    <v-layout row mb-3>
      <v-flex pt-3 v-if="ordersErrors.length > 0">
        <v-tooltip bottom color="error">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="subheading error--text font-weight-light">
              <v-icon color="error"> warning </v-icon>
              Некоторые заказы не создались, наведите для подробностей
            </span>
          </template>
          <ul v-for="item in ordersErrors">
            <li>
              <b>{{ item.pupil.fullName }}</b> - {{ item.error }}
            </li>
          </ul>
        </v-tooltip>
      </v-flex>
    </v-layout>

    <v-card>
      <v-card-text>
        <div class="mb-2">
          Всего питающихся:
          <span class="font-weight-bold">
<!--            ВРЕМЕННО ХАРДКОДОМ ПРОВЕРЯЮ СТАТУС TODO: сделать нормально -->
            {{ items.filter(o => o.status !== 6).length }}
          </span>
        </div>
        <v-flex>
          <v-data-table
            :headers="headers"
            :pagination.sync="pagination"
            :items="items"
            hide-actions
            no-data-text="Отсутствуют заявки"
            class="elevation-1 fs-teacher-table nowrapTable"
            item-key="name"
            show-select
          >
            <template slot="headerCell" slot-scope="props">
              <span slot="activator" v-if="props.header.value !== 'visit'">
                {{ props.header.text }}
              </span>
              <span v-else-if="isViewComponent" slot="activator">
                {{ props.header.text }}
              </span>
              <span v-else @click="selectAll" slot="activator">
                <with-tooltip text="Изменить явку всем ученикам">
                  <v-checkbox
                    class="fs-checkbox selectedTh"
                    :value="selectAllChecbox"
                    :label="props.header.text"
                  />
                </with-tooltip>
              </span>
            </template>
            <template v-slot:items="props">
              <tr
                :style="{
                  backgroundColor: props.item.trafficDiff ? 'lightcoral' : null
                }"
                :title="props.item.trafficDiff"
              >
                <td v-if="showNumbering">{{ props.item.idx + 1 }}</td>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.pupilId }}</td>
                <td>{{ props.item.student.name }}</td>
                <td>
                  <with-tooltip text="Изменить явку">
                    <v-checkbox
                      :disabled="isViewComponent"
                      class="fs-checkbox colTableCheckbox"
                      ref="visitRef"
                      @change="getVisitValue($event, props.item.id)"
                      v-model="props.item.visit"
                    />
                  </with-tooltip>
                </td>
                <td>
                  {{
                    props.item.foodType.name
                      ? props.item.foodType.name
                      : 'Резервный тип питания'
                  }}
                  <v-icon
                    v-if="props.item.canChangeFoodType && !isMunicipalityView"
                    class="fs-active-icon"
                    @click="changeFoodType(props.item)"
                  >
                    edit
                  </v-icon>
                </td>
                <td>{{ props.item.complex.name }}</td>
                <td>{{ props.item.student.balance.toFixed(2) }}</td>
                <td>
                  {{ props.item.price }}
                  <span v-if="props.item.additionalPrice">
                    (доп. блюда {{ props.item.additionalPrice }})
                  </span>
                </td>
                <td>{{ props.item.parentPayment }}</td>
                <td>{{ props.item.privilege }}</td>
                <td>
                  <div class="d-flex row">
                    <div class="mr-1 mt-1 pb-1">
                      {{
                        getOrderStatus(
                          props.item.status,
                          props.item.is_auto_status
                        )
                      }}
                    </div>
                    <div
                      style="padding-top: 2px"
                      v-if="setting('administrator_order_extended_ops')"
                    >
                      <v-tooltip v-if="props.item.status === 6" bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            @click="
                              changeSchoolClassOrderStatus(
                                'complete',
                                props.item.pupilId
                              )
                            "
                            v-on="on"
                            style="cursor: pointer"
                            >restart_alt
                          </v-icon>
                        </template>
                        <span>Выдать и выполнить заказ</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="[4, 5].includes(props.item.status)"
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            @click="
                              changeSchoolClassOrderStatus(
                                'cancel',
                                props.item.pupilId
                              )
                            "
                            v-on="on"
                            style="cursor: pointer"
                            >cancel
                          </v-icon>
                        </template>
                        <span>Отменить заказ</span>
                      </v-tooltip>
                      <v-tooltip v-if="props.item.status === 4" bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            @click="
                              changeSchoolClassOrderStatus(
                                'complete',
                                props.item.pupilId,
                              )
                            "
                            v-on="on"
                            style="cursor: pointer"
                            >task_alt
                          </v-icon>
                        </template>
                        <span>Выполнить заказ (списать стоимость)</span>
                      </v-tooltip>
                    </div>
                  </div>
                </td>
                <td>
                  <v-icon
                    v-if="props.item.additional_foods.length"
                    class="fs-active-icon"
                    @click="showAdditionalFood(props.item)"
                    >edit
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-card-text>
    </v-card>

    <ConfirmModal
      :dialog.sync="showModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="handlerConfirmModal"
    >
      <template v-slot:headline>{{ modalTitle }}</template>
      <template v-slot:body>
        <div v-html="modalText"></div>
      </template>
    </ConfirmModal>
    <ConfirmModal
      :dialog.sync="changeFoodTypeModal"
      :confirmBtnText="'Смена типа питания'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmChangeFoodType"
    >
      <template v-slot:headline
        >Смена типа питания
        {{ changeFoodTypeOrder ? changeFoodTypeOrder.student.name : null }}
      </template>
      <template v-slot:body>
        <v-select
          no-data-text="Ничего не выбрано"
          v-model="changeFoodTypeId"
          item-text="name"
          item-value="id"
          :items="studentFoodTypes"
          label=""
        />
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="updateOrderModal"
      :confirmBtnText="'Сохранить'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmationUpdateOrder"
    >
      <template v-slot:headline>Сохранить заказы</template>
      <template v-slot:body>
        <ul>
          <li v-for="order in ordersTrafficDiff" :key="order.id">
            {{ order.id }}, {{ order.student.name }}: {{ order.trafficDiff }}
          </li>
        </ul>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="confirmOrderModal"
      :confirmBtnText="'Подтвердить'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmationConfirmOrder"
    >
      <template v-slot:headline>Подтвердить заказы</template>
      <template v-slot:body>
        <ul>
          <li v-for="order in ordersTrafficDiff" :key="order.id">
            {{ order.id }}, {{ order.student.name }}: {{ order.trafficDiff }}
          </li>
        </ul>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="additionalFoodModal"
      :hide-confirm-button="true"
      :cancel-btn-text="'Закрыть'"
      @confirm="additionalFoodModal = false"
    >
      <template v-slot:headline>Дополнительные блюда</template>
      <template v-slot:body>
        <ul v-if="additionalFoodOrder">
          <li
            v-for="food in additionalFoodOrder.additional_foods"
            :key="food.name"
          >
            {{ food.name }}, {{ convertToRubles(food.price) }} руб.,
            {{ food.amount }} шт.
          </li>
        </ul>
        <br />
        <v-btn
          v-if="
            additionalFoodOrder &&
            additionalFoodOrder.can_delete_additional_food &&
            !isViewComponent
          "
          @click="deleteAdditionalFood"
          >Удалить указанные блюда из заявки
        </v-btn>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="removeAdditionalFoodOrderModal"
      :confirmBtnText="'Да, удалить блюда из заявки'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmationRemoveAdditionalFood"
    >
      <template v-slot:headline>Подтвердить удаление</template>
      <template v-slot:body>
        <ul v-if="additionalFoodOrder">
          <li
            v-for="food in additionalFoodOrder.additional_foods"
            :key="food.name"
          >
            {{ food.name }}
          </li>
        </ul>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import numable from '@/mixins/balancable'
import {
  cancelDryFoodType,
  cancelStatus,
  cancelSubsidyFoodType,
  changeOrdersStatus,
  chargeMoney,
  checkTable,
  confirmOrder,
  getOneOrder,
  getOrderFoodTypes,
  getPrintOrderFile,
  getPrintOrderStatus,
  getPrintOrderTaskId,
  getSettings,
  preUpdateOneOrder,
  removeAdditionalFood,
  reportAdditional,
  reportAdditionalDownload,
  reportAdditionalStatus,
  returnToTeacher,
  setDryFoodType,
  setFoodType,
  setSubsidyFoodType,
  transferTrafficClass,
  updateOneOrder,
} from '@/router/routes/admin'
import {
  getOneOrder as getOneOrderDepartment,
  getPrintOrderFileDepartment,
  getPrintOrderStatusDepartment,
  getPrintOrderTaskIdDepartment,
  reportAdditional as reportAdditionalDepartment,
  reportAdditionalDownload as reportAdditionalDownloadDepartment,
  reportAdditionalStatus as reportAdditionalStatusDepartment,
} from '@/router/routes/department'
import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import store from '@/store'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownload'
import moment from 'moment'
import { ConfirmModal } from '@/components/common'
import dateFormatter from '@/helpers/dateFormatter'
import { getOrderCreationErrors } from '@/router/routes/common'
import WithTooltip from '@/components/common/Tooltip/Tooltip'
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal.vue'
import ElementGroup from '@/components/common/ElementGroup/ElementGroup'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'AdminOrdersItem',

  mixins: [ numable ],

  components: {
    ElementGroup,
    WithTooltip,
    ConfirmModal,
    ContextHelpModal
  },

  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      showNumbering: store.getters.setting(
        'show_numbering_in_orders_school_class'
      ),
      orderExtendedOps: store.getters.setting(
        'administrator_order_extended_ops'
      ),
      isShowFeedingCount: true,
      darkTheme: Vue.config.env.darkTheme,
      isEjSettings: false,
      order: {},
      status: null,
      pagination: {
        descending: false,
        rowsPerPage: -1
      },
      items: [],
      allow_action: true,
      datepickerMenu: false,
      selectAllChecbox: false,
      showModal: false,
      modalTitle: '',
      modalText: '',
      ordersErrors: [],
      modalTypes: {
        dotation: 0,
        visit: 1,
        cancelStatus: 2,
        cancelDotation: 3,
        chargeMoney: 4,
        dryFood: 5,
        cancelDryFood: 6,
        returnToTeacher: 7
      },
      modalType: null,
      isCanBeChargedOrder: false,
      changeFoodTypeOrder: null,
      changeFoodTypeId: null,
      changeFoodTypeModal: false,
      studentFoodTypes: [],
      updateOrderModal: false,
      confirmOrderModal: false,
      additionalFoodOrder: null,
      additionalFoodModal: false,
      removeAdditionalFoodOrderModal: false,
      feedingPupilsCount: 0,
      serverDateFormat: Vue.config.format.serverDate,
      viewDataFormat: Vue.config.format.pythonDate
    }
  },

  computed: {
    ...mapGetters([ 'currentUser', 'foodTypes', 'setting' ]),

    /**
     * Определяет, находится ли пользователь в режиме просмотра муниципалитета
     * @returns {boolean} - Возвращает true, если установлено свойство meta.isMunicipality
     */
    isMunicipalityView() {
      return this.$route.meta.hasOwnProperty('isMunicipality')
    },

    /**
     * Возвращает объект с конфигурацией для панели отчетов.
     * @returns {Object} Объект с конфигурацией кнопок для панели отчетов.
     */
    reportsNavBar() {
      return {
        classOrder: {
          title: 'Заявка по классу',
          tooltip: 'Сформировать список питающихся учеников',
          /**
           * Обработчик для формирования списка питающихся учеников по классу.
           */
          handler: () => this.print('class')
        },
        additionalOrder: {
          title: 'Заявка по доп. блюдам',
          tooltip: 'Сформировать список учащихся с доп блюдами',
          /**
           * Обработчик для формирования списка учащихся с дополнительными блюдами.
           */
          handler: this.printAdditional
        }
      }
    },

    /**
     * Возвращает объект с конфигурацией для работы с заявками.
     * @returns {Object} Объект с конфигурацией кнопок для работы с заявками.
     */
    navBarForWorkingWithOrders() {
      return {
        saveOrder: {
          title: 'Сохранить заявку',
          tooltip: 'Сохранить текущие изменения по заявке на питание',
          /**
           * Обработчик для сохранения текущей заявки.
           */
          handler: this.saveOrder,
          show: this.canSave && !this.isMunicipalityView,
          icon: 'save',
          iconColor: this.darkTheme ? 'white' : 'green',
          class: 'fs-admin-btn__cancel'
        },
        confirmOrder: {
          title: 'Подтвердить заявку',
          tooltip:
            "При нажатии заказы будут переведены в статус 'Подтверждено школой'",
          /**
           * Обработчик для подтверждения заявки.
           */
          handler: this.confirmOrder,
          show: this.canConfirm && !this.isMunicipalityView,
          icon: 'thumb_up_alt',
          iconColor: this.darkTheme ? 'white' : 'green',
          class: 'fs-admin-btn__cancel'
        },
        changeOrder: {
          title: 'Сохранить изменения в заявке',
          tooltip: 'Сохраняет внесенные изменения в заявке',
          /**
           * Обработчик для сохранения изменений в заявке.
           */
          handler: this.confirmOrder,
          show: this.canChange && !this.isMunicipalityView,
          icon: 'post_add',
          iconColor: this.darkTheme ? 'white' : 'green',
          class: 'fs-admin-btn__cancel'
        },
        giveOutOrder: {
          title: 'Выдать заказ',
          tooltip: "При нажатии заказы будут переведены в статус 'Выдан'",
          /**
           * Обработчик для выдачи заказа.
           */
          handler: this.giveOutOrder,
          show: this.canGiveOut && !this.isMunicipalityView,
          icon: 'restaurant',
          iconColor: this.darkTheme ? 'white' : 'green',
          class: 'fs-admin-btn__cancel'
        },
        chargeMoney: {
          title: 'Выдать заказы',
          tooltip: "При нажатии заказы будут переведены в статус 'Выдан'",
          /**
           * Обработчик для перевода заказов в статус "Выдан".
           */
          handler: () => this.modalHandler(this.modalTypes.chargeMoney),
          show: this.isCanBeChargedOrder && !this.isMunicipalityView,
          icon: 'restaurant',
          iconColor: this.darkTheme ? 'white' : 'green',
          class: 'fs-admin-btn__cancel'
        },
        checkTable: {
          title: 'Заполнить явку по ЭЖ',
          tooltip: 'Переносит явку на заказы согласно данным ЭЖ',
          /**
           * Обработчик для переноса явки на заказы согласно данным ЭЖ.
           */
          handler: this.checkTable,
          show: this.isEjSettings && !this.isMunicipalityView,
          icon: 'cloud_sync',
          iconColor: this.darkTheme ? 'white' : 'primary',
          class: 'fs-admin-btn__cancel'
        },
        dryFood: {
          title: 'Поставить класс на сух. паек',
          tooltip: "Переводит все заявки на тип питания 'Сух. паек'",
          /**
           * Обработчик для перевода класса на сухой паек.
           */
          handler: () => this.modalHandler(this.modalTypes.dryFood),
          show: !this.isMunicipalityView,
          icon: 'bento',
          iconColor: this.darkTheme ? 'white' : 'orange',
          class: 'fs-admin-btn__cancel'
        },
        dotation: {
          title: 'Поставить класс на дотацию',
          tooltip:
            'Переводит все заявки на бюджетный тип питания (если доступен)',
          /**
           * Обработчик для перевода класса на дотацию.
           */
          handler: () => this.modalHandler(this.modalTypes.dotation),
          show: !this.isMunicipalityView,
          icon: 'money_off',
          iconColor: this.darkTheme ? 'white' : 'orange',
          class: 'fs-admin-btn__cancel'
        },
        moveAttendance: {
          title: 'Перенести явку на следующий день',
          tooltip:
            'Переносит явку аналогично текущему дню на заявки следующего дня',
          /**
           * Обработчик для переноса явки на следующий день.
           */
          handler: () => this.modalHandler(this.modalTypes.visit),
          show: this.isToday,
          disabled: !this.isValidMoveToStatus,
          icon: 'copy_all',
          iconColor: this.darkTheme ? 'white' : 'primary',
          class: 'fs-admin-btn__cancel'
        },
        cancelStatus: {
          title: 'Отменить выдачу заказа',
          tooltip:
            "Изменяет статус заказов класса c 'Выдан' на 'Изменен' для дальнейшего редактирования",
          /**
           * Обработчик для отмены выдачи заказа.
           */
          handler: () => this.modalHandler(this.modalTypes.cancelStatus),
          show: true,
          disabled: this.orderComplete,
          icon: 'no_meals',
          iconColor: this.darkTheme ? 'white' : 'error',
          class: 'fs-admin-btn__cancel'
        },
        cancelDotation: {
          title: 'Отменить дотацию по классу',
          tooltip: 'При нажатии отменяет постановку класса на дотацию',
          /**
           * Обработчик для отмены дотации по классу.
           */
          handler: () => this.modalHandler(this.modalTypes.cancelDotation),
          show: true,
          icon: 'currency_ruble',
          iconColor: this.darkTheme ? 'white' : 'error',
          class: 'fs-admin-btn__cancel'
        },
        cancelDryFood: {
          title: 'Отменить сух. паек по классу',
          tooltip: 'Отменяет тип питания сух. паек у текущего класса',
          /**
           * Обработчик для отмены сухого пайка по классу.
           */
          handler: () => this.modalHandler(this.modalTypes.cancelDryFood),
          show: true,
          icon: 'bento',
          iconColor: this.darkTheme ? 'white' : 'error',
          class: 'fs-admin-btn__cancel'
        },
        returnToTeacher: {
          title: 'Вернуть заявку учителю',
          tooltip:
            "Возвращает заявки учителю и переводит статус из 'Заказ подтвержден учителем' в 'Заказ сохранен учителем'",
          /**
           * Обработчик для возврата заявки учителю.
           */
          handler: () => this.modalHandler(this.modalTypes.returnToTeacher),
          show: this.canReturnToTeacher,
          icon: 'assignment_return',
          iconColor: this.darkTheme ? 'white' : 'orange',
          class: 'fs-admin-btn__cancel'
        }
      }
    },

    isInCurrentLearnYearOrLater() {
      const selectDate = moment(this.date, this.serverDateFormat)
      const currentDate = moment().hour(0).minute(0).second(0)
      const separationDate = moment()
        .month(6) // принимаются от 0 до 11
        .date(15) // принимаются от 1 до 31
        .hour(0)
        .minute(0)
        .second(0)
      const secondHalfYear = currentDate.isBefore(separationDate)
      const prevYear = separationDate.clone().subtract(1, 'year')
      return secondHalfYear
        ? selectDate.isBetween(prevYear, separationDate)
        : selectDate.isAfter(separationDate)
    },

    canReturnToTeacher() {
      return this.status === Vue.config.general.orderStatuses.teacherConfirm
    },
    canSave() {
      const statuses = [
        Vue.config.general.orderStatuses.draft,
        Vue.config.general.orderStatuses.teacherSaved,
        Vue.config.general.orderStatuses.teacherConfirm,
        Vue.config.general.orderStatuses.schoolSaved,
        Vue.config.general.orderStatuses.cancel
      ]

      return this.hasOrderWithStatus(this.status, statuses)
    },
    canConfirm() {
      const statuses = [
        Vue.config.general.orderStatuses.draft,
        Vue.config.general.orderStatuses.teacherSaved,
        Vue.config.general.orderStatuses.teacherConfirm,
        Vue.config.general.orderStatuses.schoolSaved,
        Vue.config.general.orderStatuses.cancel,
        Vue.config.general.orderStatuses.confirmAuto
      ]

      return this.hasOrderWithStatus(this.status, statuses)
    },

    schoolId: {
      get() {
        if (this.isMunicipalityView) {
          return this.$route.params.schoolId
        } else {
          return this.currentUser.school.id
        }
      },
      set(value) {
        return value
      }
    },

    classId() {
      return this.$route.params.classId
    },
    date() {
      return this.$route.params.date
    },
    className() {
      return this.$route.params.className
    },
    day() {
      return this.$route.params.day
    },
    month() {
      return this.$route.params.month
    },
    year() {
      return this.$route.params.year
    },

    canChange() {
      const statuses = [
        Vue.config.general.orderStatuses.schoolConfirm,
        Vue.config.general.orderStatuses.updated
      ]
      return this.hasOrderWithStatus(this.status, statuses)
    },

    canGiveOut() {
      const orderDate = new Date(this.dateServer)
      const now = new Date(moment().format(Vue.config.format.serverDate))
      return (
        orderDate - now >= 0 &&
        this.status === Vue.config.general.orderStatuses.updated
      )
    },

    isViewComponent() {
      return this.$route.meta === 'view' || this.isMunicipalityView
    },

    dateNormal() {
      return this.day + '-' + this.month + '-' + this.year
    },

    dateServer() {
      if (this.date) {
        return moment(this.date).format(Vue.config.format.serverDate)
      } else {
        return moment().format(Vue.config.format.serverDate)
      }
    },

    isToday() {
      return (
        this.dateServer ===
        moment(new Date()).format(Vue.config.format.serverDate)
      )
    },

    title() {
      const titleView = 'Просмотр заявки'
      const titleEdit = 'Редактирование заявки'
      let titleFirstPart = ''
      if (this.isViewComponent) {
        titleFirstPart = titleView
      } else {
        titleFirstPart = titleEdit
      }
      if (this.date && this.className) {
        return (
          `${titleFirstPart} - Дата: ` +
          this.dateNormal +
          ' Класс: ' +
          this.className
        )
      }
      return titleFirstPart
    },
    isValidMoveToStatus() {
      const statuses = [
        Vue.config.general.orderStatuses.schoolConfirm,
        Vue.config.general.orderStatuses.recieved,
        Vue.config.general.orderStatuses.updated,
        Vue.config.general.orderStatuses.schoolSaved,
        Vue.config.general.orderStatuses.confirmAuto
      ]

      return this.hasOrderWithStatus(this.status, statuses)
    },
    orderComplete() {
      // Статус 5 - complete  - заказ выполнен
      return this.status === Vue.config.general.orderStatuses.complete
    },
    ordersTrafficDiff() {
      return this.items.filter((item) => !!item.trafficDiff)
    },
    getFeedingPupilsCount: {
      get() {
        return this.feedingPupilsCount
      },
      set(value) {
        this.feedingPupilsCount = value
      }
    },
    getIsShowFeedingCount: {
      get() {
        return this.isShowFeedingCount
      },
      set(value) {
        this.isShowFeedingCount = value
      }
    },

    headers() {
      const headers = [
        { text: 'Лицевой счет', value: 'pupilId' },
        { text: 'ФИО ученика', value: 'student.name' },
        { text: 'Явка', value: 'visit', sortable: false },
        { text: 'Тип питания', value: 'foodType.name' },
        { text: 'Выбранный комплекс', value: 'complex.name' },
        { text: 'Баланс, руб.', value: 'student.balance' },
        { text: 'Полная стоимость, руб.', value: 'price' },
        { text: 'Доплата родителя, руб.', value: 'parentPayment' },
        { text: 'Льгота, руб.', value: 'privilege' },
        { text: 'Статус', value: 'status' },
        { text: 'Доп. блюда', value: 'additional_food', sortable: false }
      ]
      if (this.showNumbering) {
        return [
          { text: '№ п/п', value: 'idx' },
          { text: 'Номер заказа', value: 'id' },
          ...headers
        ]
      } else {
        return [ { text: '№ п/п', value: 'id' }, ...headers ]
      }
    }
  },

  created() {
    if (!this.classId) {
      this.handleBack()
    } else {
      this.loadOrder()
      this.getErrors()
    }
  },

  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    getVisitValue(status, id) {
      if (!status) {
        store.commit('setNotVisitedIds', id)
      }
    },
    handleBack() {
      this.isMunicipalityView
        ? this.navigateTo('MunicipalityOrders')
        : this.navigateTo('AdminOrders')
    },
    /**
     * Проверяет, есть ли в списке элементов элемент с указанными статусами.
     * @param {number} status - Статус.
     * @param {Array} statuses - Массив допустимых статусов.
     * @returns {boolean} Возвращает `true`, если хотя бы один элемент имеет указанный статус,
     * и `false` в противном случае.
     */
    hasOrderWithStatus(status, statuses) {
      return statuses.some((i) => i === status)
    },

    undoCompleteOrders(pupilId = null) {
      const payload = {
        orderDay: this.dateServer,
        action: 'cancel'
      }
      if (pupilId) payload.pupilId = pupilId
      else payload.schoolClassId = [ this.classId ]
      changeOrdersStatus(payload)
        .then(() => this.loadOrder(true))
        .finally(() => store.commit('setShowPreloader', false))
    },
    async changeSchoolClassOrderStatus(action, pupilId = null) {
      if (!this.allow_action) return
      this.allow_action = false
      const payload = {
        orderDay: this.dateServer,
        action: action
      }
      if (pupilId) payload.pupilId = pupilId
      else payload.schoolClassId = [ this.classId ]
      try {
        await changeOrdersStatus(payload)
      } catch (e) {
      } finally {
        this.allow_action = true
        store.commit('setShowPreloader', false)
        await this.loadOrder(true)
      }
    },

    deleteAdditionalFood() {
      this.removeAdditionalFoodOrderModal = true
    },
    getErrors() {
      const payload = {
        orderDate: this.date,
        schoolClass: this.classId
      }
      getOrderCreationErrors(payload).then((res) => {
        this.ordersErrors = res.data
      })
    },
    async confirmationRemoveAdditionalFood(value) {
      if (!value) {
        this.removeAdditionalFoodOrderModal = false
        return
      }
      await removeAdditionalFood(this.additionalFoodOrder.id)
      this.additionalFoodOrder = null
      this.additionalFoodModal = false
      await this.loadOrder(true)
    },
    async changeFoodType(order) {
      this.changeFoodTypeOrder = order
      this.changeFoodTypeModal = true
      this.changeFoodTypeId = null
      this.studentFoodTypes = []
      try {
        const { data } = await getOrderFoodTypes({
          schoolId: this.schoolId,
          id: order.pupilId,
          orderId: order.id
        })
        this.studentFoodTypes = data.orderFoodTypes
      } catch (e) {
        notify.error(
          'Не удалось получить доступный список типов питания, попробуйте повторить позже'
        )
      }
      await this.loadOrder(true)
      store.commit('setShowPreloader', false)
    },
    showAdditionalFood(order) {
      this.additionalFoodOrder = order
      this.additionalFoodModal = true
    },
    async confirmChangeFoodType(value) {
      if (!value) {
        return
      }
      if (!this.changeFoodTypeId) {
        return
      }
      try {
        await setFoodType(this.changeFoodTypeOrder.id, this.changeFoodTypeId)
        await this.loadOrder(true)
        notify.success('Тип питания в заявке успешно изменен')
      } catch (e) {
        notify.error(e.response.data.message)
      }
      await this.loadOrder(true)
      store.commit('setShowPreloader', false)
    },
    getOrderStatus(value, auto) {
      switch (value) {
        case 1:
          return 'Черновик'
        case 2:
          return 'Подтвержден учителем'
        case 3:
          return 'Подтвержден школой'
        case 4:
          return 'Выдан'
        case 5:
          if (auto) return 'Выполнен (без списания)'
          return 'Выполнен'
        case 6:
          return 'Отменен'
        case 7:
          return 'Сохранен учителем'
        case 8:
          return 'Сохранен школой'
        case 9:
          return 'Подтвержден автоматически'
        case 10:
          return 'Изменен'
        default:
          break
      }
    },

    async loadOrder(resetItems, set_visit = false) {
      try {
        const PAYLOAD = {
          classId: this.classId,
          date: this.date
        }
        const method = this.isMunicipalityView
          ? getOneOrderDepartment
          : getOneOrder
        const response = await method(PAYLOAD)

        const { data: orders } = response
        this.status = 1
        this.setChargeMoneyButtonStatus(orders)

        if (!set_visit) {
          // store.commit('clearVisit')
          this.setItems(orders, resetItems)
          this.items.forEach((item) => {
            if (!item.visit) {
              store.commit('setNotVisitedIds', item.id)
            }
          })
        }

        if (set_visit) {
          this.setItems(orders, resetItems)

          this.items.forEach((item, index, list) => {
            if (
              store.getters.notVisitedIds.findIndex((id) => id === item.id) !==
              -1
            ) {
              list[index].visit = false
            }
          })
        }

        if (this.isMunicipalityView) {
          this.schoolId = response.data.data.school.id
        }
        if (!this.isMunicipalityView) {
          await this.getSettings()
        }
      } catch (error) {
        // notify.error('Ошибка загрузки заявки') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getSettings() {
      try {
        const PAYLOAD = {
          school_id: this.schoolId
        }
        const response = await getSettings(PAYLOAD)
        this.isEjSettings = response.data.data.fillMealCardEj
        // PM-898 временно убираем
        // this.mealOrderSubmissionTime = response.data.data.mealOrderSubmissionTime
      } catch (error) {
        // notify.error('Ошибка загрузки настроек') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    checkStudetVisit(item) {
      const itemStatus = _.get(item, 'status', null)
      // const isPupilTransferred = _.get(item, 'isPupilTransferred', false)
      const configCancelStatus = Vue.config.general.orderStatuses.cancel
      // if (isPupilTransferred !== null) {
      //   return isPupilTransferred
      // }
      return itemStatus !== configCancelStatus
    },
    getPayload() {
      return {
        id: this.classId,
        date: this.date,
        data: {
          statusList: _.map(this.items, (item) => {
            return {
              order_id: item.id,
              to_next_status: item.visit
            }
          })
        }
      }
    },
    async confirmationUpdateOrder(value) {
      this.updateOrderModal = false
      if (value) {
        await this.saveOrder(true)
      }
      await this.loadOrder(true)
    },
    async confirmationConfirmOrder(value) {
      this.confirmOrderModal = false
      if (value) {
        await this.confirmOrder(true)
      }
      await this.loadOrder(true)
    },
    async saveOrder(force) {
      if (!force && this.ordersTrafficDiff.length) {
        this.updateOrderModal = true
        return
      }
      preUpdateOneOrder(this.getPayload())
        .then((response) => {
          if (response.data.success) {
            notify.success('Заявка сохранена')
          }
          this.loadOrder(true)
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    async confirmOrder(force) {
      if (!force && this.ordersTrafficDiff.length) {
        this.confirmOrderModal = true
        return
      }
      confirmOrder(this.getPayload())
        .then((response) => {
          if (response.data.success) {
            notify.success('Заявка подтверждена')
          }
          this.loadOrder(true)
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    async giveOutOrder() {
      updateOneOrder(this.getPayload())
        .then((response) => {
          if (response.data.success) {
            notify.success('Заказ выдан')
          }
          this.loadOrder(true)
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    async printTurnout(payload) {
      const callback = this.resetPageFileLoaded
      const method1 = this.isMunicipalityView
        ? getPrintOrderTaskIdDepartment
        : getPrintOrderTaskId
      const method2 = this.isMunicipalityView
        ? getPrintOrderStatusDepartment
        : getPrintOrderStatus
      const method3 = this.isMunicipalityView
        ? getPrintOrderFileDepartment
        : getPrintOrderFile
      try {
        await asyncReqestsForDownload(
          method1,
          method2,
          method3,
          payload,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки табеля')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async print(type) {
      const PAYLOAD = {
        order_day: this.dateServer,
        school_id: this.schoolId
      }
      if (type === 'class') {
        PAYLOAD.reportType = 'newForm'
        PAYLOAD.class_id = this.classId
      }
      await this.printTurnout(PAYLOAD)
    },
    async printAdditional() {
      try {
        const PAYLOAD = {
          school_id: this.schoolId,
          data: {
            order_day: this.dateServer,
            class_id: this.classId
          }
        }
        const callback = this.resetPageFileLoaded
        const methodRequest = this.isMunicipalityView
          ? reportAdditionalDepartment
          : reportAdditional
        const methodStatus = this.isMunicipalityView
          ? reportAdditionalStatusDepartment
          : reportAdditionalStatus
        const methodDownload = this.isMunicipalityView
          ? reportAdditionalDownloadDepartment
          : reportAdditionalDownload
        await asyncReqestsForDownload(
          methodRequest,
          methodStatus,
          methodDownload,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки файла') //pass
      } finally {
        this.resetPageFileLoaded()
      }
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    },
    checkTable() {
      // заполняет табель питания по Электронному Журналу
      const payload = {
        date: this.date,
        classId: this.classId,
        schoolId: this.schoolId
      }
      checkTable(payload)
        .then((response) => {
          const responseArray = response.data.data
          this.setItemsVisitFromEG(responseArray)
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    // установить массив по электронному журналу
    setItemsVisitFromEG(responseArray) {
      this.items.map((item) => {
        _.forEach(responseArray, (responseItem) => {
          if (item.pupilId == responseItem.pupilId) {
            Vue.set(item, 'visit', responseItem.traffic)
          }
        })
        return item
      })
    },
    setItems(orders, resetItems) {
      const itemsArray = _.map(orders, (item, idx) => {
        'newForm'
        let addPrice = 0
        if (item.additional_food) {
          for (const food of item.additional_food) {
            addPrice += food.price * food.amount
          }
        }
        return {
          idx: idx,
          id: item.id,
          student: {
            id: _.get(item, 'pupil.id', null),
            name: _.get(item, 'pupil.full_name', ''),
            balance: this.convertToRubles(_.get(item, 'pupil.balance', 0))
            // isCredit: item.pupil.isCredit,
            // creditAmount: this.convertToRubles(
            //   _.get(item, 'pupil.creditAmount', 0)
            // )
          },
          foodType: {
            name: _.get(item, 'meal_type.name', null)
          },
          canChangeFoodType: item.can_change_food_type,
          trafficDiff: item.traffic && item.traffic.diff_text,
          complex: _.get(item, 'complect', null),
          price: this.convertToRubles(_.get(item, 'meal_type.price', 0)),
          parentPayment: this.roundRubles(
            this.convertToRubles(_.get(item, 'meal_type.price', 0)) -
              this.convertToRubles(_.get(item, 'privilege_amount', 0))
          ),
          privilege: this.convertToRubles(_.get(item, 'privilege_amount', 0)),
          totalPrice: this.convertToRubles(item.total_price),
          additionalPrice: this.convertToRubles(addPrice),
          visit: this.checkStudetVisit(item),
          status: item.status,
          is_auto_status: _.get(item, 'status_received_is_auto', null),
          pupilId: _.get(item, 'pupil.id', null),
          can_delete_additional_food: item.can_delete_additional_food,
          additional_foods: item.additional_food ? item.additional_food : []
        }
      })
      if (resetItems) {
        this.items = itemsArray
        return
      }
      this.items = [ ...this.items, ...itemsArray ]
    },
    modalHandler(type) {
      switch (type) {
        case this.modalTypes.dotation:
          this.modalType = this.modalTypes.dotation
          this.modalTitle = `Подтверждение установки дотации`
          this.modalText = `Вы подтверждаете
        <span class="highlighted">постановку ${this.className} класса на дотацию</span>?`
          break
        case this.modalTypes.cancelDotation:
          this.modalType = this.modalTypes.cancelDotation
          this.modalTitle = `Отмена дотации`
          this.modalText = `Вы подтверждаете
        <span class="highlighted">отмену простановки  ${this.className} класса на дотацию</span>  на сегодняшний день?`
          break
        case this.modalTypes.dryFood:
          this.modalType = this.modalTypes.dryFood
          this.modalTitle = 'Подтверждение установки сух. пайка'
          this.modalText = `Вы подтверждаете
        <span class="highlighted">постановку ${this.className} класса на сух. паек</span>?`
          break
        case this.modalTypes.cancelDryFood:
          this.modalType = this.modalTypes.cancelDryFood
          this.modalTitle = `Отмена сух. пайка`
          this.modalText = `Вы подтверждаете <span class="highlighted">отмену простановки  ${this.className} класса на сух. паек</span>  на сегодняшний день?`
          break
        case this.modalTypes.visit:
          this.modalType = this.modalTypes.visit
          this.modalTitle = `Подтверждение переноса явки учеников`
          this.modalText = `Вы подтверждаете <span class="highlighted">перенос явки учеников</span> на следующий учебный день?`
          break
        case this.modalTypes.cancelStatus:
          this.modalType = this.modalTypes.cancelStatus
          this.modalTitle = `Подтверждение отмены статуса заявки`
          this.modalText = `Вы подтверждаете отмену статуса заявки?`
          break
        case this.modalTypes.chargeMoney:
          this.modalType = this.modalTypes.chargeMoney
          this.modalTitle = `Подтверждение списания заказов`
          this.modalText = `Вы подтверждаете списание заказов на ${this.dateNormal}?`
          break
        case this.modalTypes.returnToTeacher:
          this.modalType = this.modalTypes.returnToTeacher
          this.modalTitle = 'Подтверждение возвращения заявки учителю'
          this.modalText = 'Вы подтверждаете возвращение заявки учителю?'
        default: {
          break
        }
      }
      this.showModal = true
    },
    async handlerConfirmModal(value) {
      if (value) {
        switch (this.modalType) {
          case this.modalTypes.dotation: {
            await this.setDotation(true)
            break
          }
          case this.modalTypes.cancelDotation: {
            await this.setDotation(false)
            break
          }
          case this.modalTypes.dryFood: {
            await this.setDryFood(true)
            break
          }
          case this.modalTypes.cancelDryFood: {
            await this.setDryFood(false)
            break
          }
          case this.modalTypes.visit: {
            await this.transferTrafficRequest()
            break
          }
          case this.modalTypes.cancelStatus: {
            await this.cancelStatusRequest()
            break
          }
          case this.modalTypes.chargeMoney: {
            await this.chargeMoneyRequest()
            break
          }
          case this.modalTypes.returnToTeacher: {
            await this.returnToTeacher()
            break
          }
          default:
            break
        }
      }
    },
    async setDotation(value) {
      let requestMethod = null
      let resultText = ''
      if (value) {
        requestMethod = setSubsidyFoodType
        resultText = `${this.className} класс успешно поставлен на дотацию.`
      } else {
        requestMethod = cancelSubsidyFoodType
        resultText = `${this.className} класс успешно снят с дотации.`
      }

      try {
        const classId = this.classId
        await requestMethod(this.schoolId, this.date, [ classId ])
        await this.loadOrder(true)
        notify.success(resultText)
      } catch (error) {
        notify.error('Ошибка установки дотации') //pass
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async setDryFood(value) {
      let requestMethod = null
      let resultText = ''
      if (value) {
        requestMethod = setDryFoodType
        resultText = `${this.className} класс успешно поставлен на сух. паек.`
      } else {
        requestMethod = cancelDryFoodType
        resultText = `${this.className} класс успешно снят с сух. пайка.`
      }

      try {
        const classId = this.classId
        await requestMethod(this.schoolId, this.date, [ classId ])
        await this.loadOrder(true)
        notify.success(resultText)
      } catch (error) {
        notify.error('Ошибка установки сух. пайка') //pass
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async transferTrafficRequest() {
      try {
        const PAYLOAD = {
          classId: this.classId,
          schoolId: this.schoolId,
          data: {
            statusList: _.map(this.items, (item) => {
              return {
                order_id: item.id,
                to_next_status: item.visit
              }
            })
          }
        }
        const response = await transferTrafficClass(PAYLOAD)
        const workingDay =
          response.data.data.workingDate || 'следующий рабочий день'
        if (response.data.success) {
          notify.success(
            `Явка учеников успешно перенесена на ${dateFormatter(
              workingDay,
              'pythonDate'
            )}`
          )
        }
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async cancelStatusRequest() {
      try {
        const PAYLOAD = {
          classId: this.classId,
          schoolId: this.schoolId,
          date: this.date
        }

        await cancelStatus(PAYLOAD)
        await this.loadOrder(true)
        notify.success(`Статус заявки успешно изменён`)
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async returnToTeacher() {
      try {
        const PAYLOAD = {
          schoolId: this.schoolId,
          classId: this.classId,
          date: this.date
        }
        await returnToTeacher(PAYLOAD)
        await this.loadOrder()
        notify.success(`Заявка возвращена учителю`)
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async chargeMoneyRequest() {
      try {
        const PAYLOAD = {
          classId: this.classId,
          schoolId: this.schoolId,
          date: this.dateNormal,
          notVisitedIds: store.getters.notVisitedIds
        }

        await chargeMoney(PAYLOAD)
        await this.loadOrder(true, true)
        notify.success(`Списание заказов успешно выполнено`)
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    selectAll() {
      this.selectAllChecbox = !this.selectAllChecbox
      // store.commit('clearVisit')
      this.items.map((item) => {
        item.visit = this.selectAllChecbox

        if (!this.selectAllChecbox) {
          store.commit('setNotVisitedIds', item.id)
        }

        return item
      })
    },
    navigateTo(where) {
      const data = {
        data: this.date,
        day: this.day,
        month: this.month,
        year: this.year,
        houseId: this.$route.params.houseId
      }
      this.$router.push({ name: where, params: data })
    },
    setChargeMoneyButtonStatus(orders) {
      this.isCanBeChargedOrder =
        orders.some((item) => item.status_received_is_auto) &&
        this.isChargeMoneyDay()
    },
    isChargeMoneyDay() {
      const dayNow = new Date()
      const orderDate = new Date(this.dateServer)
      const isChargeDay = Math.floor(
        Math.abs(dayNow.getTime() - orderDate.getTime()) / (1000 * 3600 * 24)
      )
      return isChargeDay < 7
    }
    // PM-898 временно убираем
    // checkMealOrderSubmissionTime() {
    //   if(!this.mealOrderSubmissionTime) {
    //     return false
    //   }
    //   const dayNow = new Date()
    //   const dateTimeServer = this.dateServer + 'T' + this.mealOrderSubmissionTime
    //   const mealOrderSubmissionDateTime = new Date(dateTimeServer)
    //   return dayNow < mealOrderSubmissionDateTime
    // }
  }
}
// @TODO добавить проверку, чтобы скрывать по ЭЖ...
</script>
