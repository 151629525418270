<script>
import { getClasses, restoreOrders } from "@/router/routes/superadmin"
import store from "@/store"
import moment from "moment"
import notify from '@/utils/notifications/toastNotification'
import ChangeMealType from "@/components/moduleadmin/ChangeMealType/ChangeMealType.vue"

export default {
  name: "OrderRestoreDefault",
  components: {
    ChangeMealType
  },
  data: () => {
    return {
      dialog: false,
      globalChangeMealsTypeDialog: false,
      schools: [],
      schoolClasses: [],
      selectedSchool: null,
      selectedClass: null,
      selectedDate: moment().format('YYYY-MM-DD'),
      inProgress: false,
    }
  },
  props: {
    schools: {
      type: Array,
      required: true
    }
  },
  methods: {
    async getSchoolClasses() {
      this.schoolClasses = []
      try {
        const response = await getClasses({
          school_id: this.selectedSchool
        })
        this.schoolClasses = response.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async processOrders () {
      this.inProgress = true
      try {
        if (!this.selectedSchool) {
          notify.error('Не выбрана школа')
          throw new Error('Не выбрана школа')
        }
        const payload = {
          school: this.selectedSchool,
          schoolClass: this.selectedClass,
          date: this.selectedDate
        }
        await restoreOrders(payload)
      } catch (e) {

      }
      this.inProgress = false
      store.commit('setShowPreloader', false)
    }
  },
  watch: {
    async selectedSchool(val) {
      if (val) {
        await this.getSchoolClasses()
      }
      store.commit('setShowPreloader', false)
    },
  }
}
</script>

<template>
<div>
  <v-btn
    color="primary"
    @click.stop="globalChangeMealsTypeDialog = true"
    small
  >
     Массовая смена типов питания
  </v-btn>
  <v-btn
    color="primary"
    @click.stop="dialog = true"
    small
  >
    Актуализация заявок
  </v-btn>
  <v-dialog
    v-model="globalChangeMealsTypeDialog"
    max-width="1300"
    persistent
  ><ChangeMealType @close="globalChangeMealsTypeDialog = false"/></v-dialog>
  <v-dialog
    v-model="dialog"
    max-width="500"
  >
    <v-card>
      <v-card-title>Актуализация заявок по текущим данным</v-card-title>
      <v-card-text>
        <v-alert color="primary">
          Операция восстановит заявки за выбранную дату к значениям, которые указаны в карточке ребенка.
        </v-alert>
        <div>
          <v-autocomplete
            solo
            clearable
            :items="schools"
            v-model="selectedSchool"
            item-text="fullName"
            item-value="id"
            label="Школа"
          />
          <v-autocomplete
            solo
            clearable
            :items="schoolClasses"
            v-model="selectedClass"
            item-text="name"
            item-value="id"
            label="Класс (опционально)"
          />
          <v-date-picker
              v-model="selectedDate"
              no-title
              full-width
              locale="ru"
              first-day-of-week="1"
          />
        </div>
      </v-card-text>
      <v-card-actions class="d-flex row justify-space-between">
        <v-btn
          color="dark"
          @click="dialog = false"
        >
          Отмена
        </v-btn>
        <v-btn
          color="primary"
          @click="processOrders"
          :disabled="inProgress"
        >
          Выполнить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<style scoped>

</style>
