<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import ImagePreviewModal from '@/components/common/QualityControl/modal/ImagePreviewModal.vue'

export default {
  name: 'EsiaConnectButton',
  components: { ImagePreviewModal },
  props: [ 'isAuth' ],
  computed: {
    ...mapGetters([ 'currentUser', 'setting' ]),
  },
  methods: {
    async doAuth() {
      // TODO: переделать на тестовый
      document.location.href = "https://xn--33-6kcadhwnl3cfdx.xn--p1ai/esia/vld_meal.php"
      store.commit('setShowPreloader', false)
    }
  },
}
</script>

<template>
<v-tooltip v-if="setting('esia_auth_supplier')">
  <template v-slot:activator="{ on }">
    <!--  Гайдлайны кнопки ГОСУСЛУГИ  -->
    <!--  https://www.figma.com/proto/8WTah2svbe6snsrHFl5zkC/Sign-in-Gosuslugi?page-id=0%3A1&type=design&node-id=5-4264&viewport=-103%2C657%2C0.03&t=eFfu3UkCrIu0RtWz-1&scaling=scale-down&hide-ui=1&starting-point-node-id=5%3A4264&mode=design  -->
    <button @click="doAuth" style='height: 48px'>
      <img src="@/assets/img/gosuslugiID_znak+text_S_blue_outline.svg" alt='Войти через Госуслуги'/>
    </button>
  </template>
</v-tooltip>
</template>

<style scoped>
.color-blue {
  color: #f3f5ff;
}
</style>
