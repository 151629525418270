import Vue from 'vue'
import axios from "axios";

// DEBUG ITEMS
export const doErrorRequest = (errorType) => axios.get(
  Vue.config.server + '/' + Vue.config.api.superadmin.debug.error + `?${errorType}`
)
//

export const getMunicipalities = () => axios.get(Vue.config.server + '/' + Vue.config.api.superadmin.municipalities)

const municipalUsersUrl = Vue.config.server + '/' + Vue.config.api.superadmin.municipalUsers

export const getMunicipalUsers = payload => axios.get(municipalUsersUrl, { params: payload })
export const createMunicipalUser = payload => axios.post(municipalUsersUrl, payload)
export const removeMunicipalUser= payload => axios.delete(municipalUsersUrl, { data: payload })

export const getBuffetUsers = () => axios.get(
  Vue.config.server + '/' + Vue.config.api.superadmin.buffet.users
)

export const createBuffetUser = (payload) => axios.post(
  Vue.config.server + '/' + Vue.config.api.superadmin.buffet.users,
  payload
)


export const deleteNewsByID = payload =>
  axios.delete(
    Vue.config.server + '/' + Vue.config.api.superadmin.updates.delete + '/' + payload.params.id
  )
export const updateLastNews = payload =>
  axios.put(
    Vue.config.server + '/' + Vue.config.api.superadmin.updates.edit,
    payload.params
  )
export const getUpdatesNewsList = payload => axios.get(Vue.config.server + '/' + Vue.config.api.superadmin.updates.all, payload)
export const getUpdatesNewsLatest = payload => axios.get(Vue.config.server + '/' + Vue.config.api.superadmin.updates.latest, payload)
export const createUpdatesNews = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.updates.new,
    payload.params
  )
export const customFindOrders = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.orders.customSearch,
    payload
  )
export const getUserByID = id => axios.get(Vue.config.server + '/' + Vue.config.api.superadmin.users.getUserByID.replace('$userId', id))
export const deleteUsers = payload => axios.post(Vue.config.server + '/' + Vue.config.api.superadmin.users.delete, payload)

export const getProductsStorehouse = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.schools.all.replace(
        '$department_id',
        payload.department_id
      ),
    { params: payload.params }
  )

export const getSupplierDetail = id => {
  const url = Vue.config.server + '/' + Vue.config.api.superadmin.suppliers.detail.replace('$id', id)
  return axios.get(url)
}

/**
 * Обновляет информацию о поставщике.
 * @param {string|number} id - Идентификатор поставщика, для которого требуется обновить информацию.
 * @param {Object} data - Объект с данными, содержащий обновленные сведения о поставщике.
 * @returns {Promise<Object>} - Объект с результатом запроса, содержащий поля `success` и `data`.
 */
export const updateSupplierDetail = (id, data) => {
  const url = Vue.config.server + '/' + Vue.config.api.superadmin.suppliers.detail.replace('$id', id)
  return axios.patch(url, data)
}

export const getSuppliers = params => axios.get(
  `${Vue.config.server}/${Vue.config.api.superadmin.supplierList}`,
  { params }
)

export const getSuppliersList = params => axios.get(
  `${Vue.config.server}/${Vue.config.api.superadmin.supplierShortList}`,
  { params }
)

export const getLogModelsList = () => axios.get(
  `${Vue.config.server}/${Vue.config.api.superadmin.logs.modelsList}`
)

export const getLogActionsList = () => axios.get(
  `${Vue.config.server}/${Vue.config.api.superadmin.logs.actionsList}`
)

export const getLogSearch = (params) => axios.get(
  `${Vue.config.server}/${Vue.config.api.superadmin.logs.doSearch}`, { params }
)

export const runSyncRequest = payload => axios.post(
  `${Vue.config.server}/${Vue.config.api.superadmin.sync.new}`,
  payload
)

export const getOrganizersPure = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.suppliers,
    { params: payload }
  )

export const getOrganizers = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.all,
    { params: payload }
  )


export const storeOrganizer = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.all,
    payload
  )

export const getOrganizer = id =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.one.replace('$id', id),
  )

export const updateOrganizer = (id, payload) =>
  axios.patch(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.one.replace('$id', id),
    payload
  )

export const checkSberAuth = (payload) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.organizers.checkSber,
    payload
)

export const disableOrganizer = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.disable,
    payload
  )

export const enableOrganizer = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.enable,
    payload
  )

export const getLog = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.log.all,
    { params: payload }
  )

export const getDepartments = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.departments.all,
    { params: payload }
  )

export const assignDepartment = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.departments.one,
    payload
  )


export const getSchoolsByDepartment = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.schools.byDepartment.replace("$department_id", payload)
  )

export const getSchools = (payload) =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.schools.all,
    payload
  )

export const getSchoolsBySupplier = (id) => axios.get(
  Vue.config.server + '/' + Vue.config.api.superadmin.organizers.schools.byId + `?supplier=${id}`
)
export const getClasses = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.schools.classes.replace('$school_id', payload.school_id)
  )

export const restoreOrders = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.restore, payload
  )
export const getAdmins = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.schools.admins.replace('$school_id', payload.school_id)
  )
export const getTeacher = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.schools.teacher
      .replace('$school_id', payload.school_id)
      .replace('$class_id', payload.class_id),
  )
export const getParents = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.schools.parents
      .replace('$school_id', payload.school_id)
      .replace('$class_id', payload.class_id),
  )

export const authAdmin = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.auth.admin
      .replace('$school_id', payload.school_id)
      .replace('$user_id', payload.user_id),
  )

export const authTeacher = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.auth.teacher
      .replace('$school_id', payload.school_id)
      .replace('$class_id', payload.class_id)
      .replace('$user_id', payload.user_id),
  )

export const authParent = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.auth.parent
      .replace('$school_id', payload.school_id)
      .replace('$user_id', payload.user_id)
      .replace('$pupil_id', payload.pupil_id),
  )

export const authPupil = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.auth.pupil
      .replace('$pupil_id', payload.pupil_id),
  )

export const authDepartment = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.auth.department
      .replace('$department_id', payload.department_id)
  )

export const authSuperDepartment = userId =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.auth.super_department
      .replace('$userId', userId)
  )

export const getDepartmentUsers = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.department_users
  )

export const getSuperDepartmentUsers = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.super_department_users
  )

export const authSupplier = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.auth.supplier
      .replace('$supplier_id', payload.supplier_id)
  )

export const getAccessSchools = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.schools.all,
    { params: payload }
  )

export const toggleSchoolAccess = payload =>
  axios.patch(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.schools.one.replace('$school_id', payload)
  )

export const getStudents = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.schools.students
      .replace('$school_id', payload.schoolId)
      .replace('$classId', payload.classId)
  )

export const getStudent = id =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.superadmin.pupil
      .replace(':id', id)
  )

export const updateOrder = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.orders.customEdit,
    payload
  )

export const addOrder = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.orders.addNewOrder,
    payload
  )

export const updateOrdersMulti = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.orders.customEditMulti,
    payload
  )

export const updateMealTypesMulti = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.orders.customEditMealTypesMulti,
    payload
  )

export const getMealTypesList = school_id =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.superadmin.schools.meal_types_list
        .replace(':school_id', school_id)
  )
export const getDepartmentMealTypesList = payload =>
  axios.get(
      Vue.config.server +
        '/' +
        Vue.config.api.superadmin.departments.mealTypes,
        {
          params: payload
        }
      )

export const massiveChangeMealType = payload => axios.patch(
  Vue.config.server + '/' + Vue.config.api.superadmin.changeMealType,
  payload
)

export const getStudentsBySchool = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.schools.students_school
      .replace('school_id', `?school_pk=${payload.school_id}`)
  )


export const getOrders = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.all,
    { params: payload.params }
  )

export const createUserHelp = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.userHelp.new, payload
  )

export const getUserHelp = () =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.superadmin.userHelp.all
  )

export const removeUserHelp = (payload) =>
  axios.delete(
    Vue.config.server + '/' + Vue.config.api.superadmin.userHelp.edit
      .replace(':id', payload.id)
  )

export const getUserHelpItem = (payload) =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.superadmin.userHelp.edit
      .replace(':id', payload.id)
  )

export const editUserHelpItem = (payload) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.userHelp.edit
      .replace(':id', payload.id),
    payload
  )

export const issueClassOrders = payload =>
  axios.patch(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.issueClass
      .replace('$classId', payload.classId)
      .replace('$year', payload.year)
      .replace('$month', payload.month)
      .replace('$day', payload.day),
    payload.data
  )

export const cancelClassOrders = payload =>
  axios.patch(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.cancelMultiple,
    payload
  )

export const setNotification = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.notifications.all,
    payload.data
  )

export const setNotificationEveryone = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.notifications.everyone,
    payload.data
  )

export const getMealTypes = supplierId =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.mealTypes.replace('$supplierId', supplierId)
  )

export const changeMealType = (orderId, mealTypeId, comment) =>
  axios.patch(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.changeMealType.replace('$orderId', orderId).replace('$mealTypeId', mealTypeId),
    { comment: comment }
  )

export const issueOrder = (orderId, comment) =>
  axios.patch(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.issueOne.replace('$orderId', orderId),
    { comment: comment }
  )

export const cancelOrder = (orderId, comment) =>
  axios.patch(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.cancelOne.replace('$orderId', orderId),
    { comment: comment }
  )

export const setSubsidy = payload => {
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.setSubsidy
      .replace('$schoolId', payload.schoolId)
      .replace('$year', payload.year)
      .replace('$month', payload.month)
      .replace('$day', payload.day),
    { classIds: payload.classIds, comment: payload.comment }
  )
}

export const cancelSubsidy = payload => {
  axios.delete(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.setSubsidy
      .replace('$schoolId', payload.schoolId)
      .replace('$year', payload.year)
      .replace('$month', payload.month)
      .replace('$day', payload.day),
    { data: { classIds: payload.classIds, comment: payload.comment } }
  )
}

export const setDryFood = payload => {
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.setDryFood
      .replace('$schoolId', payload.schoolId)
      .replace('$year', payload.year)
      .replace('$month', payload.month)
      .replace('$day', payload.day),
    { classIds: payload.classIds, comment: payload.comment }
  )
}

export const cancelDryFood = payload => {
  axios.delete(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.setDryFood
      .replace('$schoolId', payload.schoolId)
      .replace('$year', payload.year)
      .replace('$month', payload.month)
      .replace('$day', payload.day),
    { data: { classIds: payload.classIds, comment: payload.comment } }
  )
}


export const getDepartmentLogs = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.log.departmentLogs,
    { params: payload.params }
  )

export const getDepartmentslist = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.departments.list
  )

export const getAdminTeacherPupilCardLogs = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.log.adminTeacherPupilCardLogs,
    { params: payload.params }
  )

export const getExceptionInfo = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.exceptionInfo,
    { params: payload.params }
  )

export const getExceptionsCount = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.exceptionsCount
  )


export const getOrdersLogs = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.logs,
    { params: payload.params }
  )


export const getOrderDefaultLog = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.orders.defaultLog,
    { params: payload.params }
  )


export const setOrganizerPassword = payload =>
  axios.patch(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.organizers.set_password.replace(
      '$id',
      payload.id
    ),
    payload.data
  )


export const uploadPayments = (payload, id, config) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.superadmin.payments.upload.replace('$supplier_id', id),
    payload,
    config
  )


export const getPayments = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.payments.list,
    { params: payload.params }
  )


export const getSettings = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.settings
  )

export const updateSettings = payload =>
  axios.put(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.settings,
    payload.data
  )

export const getAllReports = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.superadmin.reports.all
  )

export const getReportFileTaskId = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.superadmin.reports.task_id,
    payload,
    { preloaderState: false }
  )

export const getReportFileStatus = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.superadmin.reports.status
        .replace('$task_id', payload.task_id),
    { preloaderState: false }
  )

export const getReportFileDownload = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.superadmin.reports.download
        .replace('$task_id', payload.task_id),
    { responseType: 'blob', preloaderState: false }
  )


export const editMealTypes = (payload) =>
  axios.post(
    `${Vue.config.server}/${Vue.config.api.superadmin.orders.customEditMealTypesMulti}`,
    payload
  )

export const resetMealTypes = (payload) => {
  return axios.post(
    `${Vue.config.server}/${Vue.config.api.superadmin.pupils.restoreMealType}`,
    { ...payload }
  )
}


// Костыль на Департамент школ-интернат
const boardingSchoolsDepartmentUrl =
  Vue.config.server + '/' + Vue.config.api.superadmin.boardingSchools.departments

export const getBoardingSchoolsDepartments = (params) =>
  axios.get(boardingSchoolsDepartmentUrl, { params })

export const getBoardingSchoolsDepartmentList = () =>
  axios.get(boardingSchoolsDepartmentUrl + "list/")

export const getBoardingSchoolsDepartment = ({ id }) =>
  axios.get(`${boardingSchoolsDepartmentUrl}${id}`)

export const updateBoardingSchoolsDepartment = ({ data, id }) =>
  axios.patch(`${boardingSchoolsDepartmentUrl}${id}/`, data)

export const createBoardingSchoolsDepartment = ({ data }) =>
  axios.post(`${boardingSchoolsDepartmentUrl}`, data)

const boardingSchoolsUsersUrl =
  Vue.config.server + '/' + Vue.config.api.superadmin.boardingSchools.users

export const getBoardingSchoolsUsers = (params) =>
  axios.get(boardingSchoolsUsersUrl, { params })

export const getBoardingSchoolsUser = ({ id }) =>
  axios.get(`${boardingSchoolsUsersUrl}${id}`)

export const updateBoardingSchoolsUser = ({ data, id }) =>
  axios.patch(`${boardingSchoolsUsersUrl}${id}/`, data)

export const createBoardingSchoolsUser = ({ data }) =>
  axios.post(`${boardingSchoolsUsersUrl}`, data)

export const deleteBoardingSchoolsUser = ({ id }) =>
  axios.delete(`${boardingSchoolsUsersUrl}${id}/`)


export const createOrdersForPastDay = (payload) =>
  axios.post(
    `${Vue.config.server}/${Vue.config.api.superadmin.orders.createOrdersForPastDay}`,
    { ...payload }
  )

export const getOrdersLogForPastDay = (payload) =>
  axios.get(
    `${Vue.config.server}/${Vue.config.api.superadmin.orders.getOrdersLogForPastDay}`,
    {
      ...payload,
      paramsSerializer: (payload) => {
        return Object.entries(payload)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      }
    }
  )

export const fetchTicketList = (payload) =>
  axios.get(
    `${Vue.config.server}/${Vue.config.api.supportChat.ticketList}`,
    {
      ...payload,
    }
  )

export const sendTicketAnswer = ({ id, data }) =>
  axios.patch(
    `${Vue.config.server}/${Vue.config.api.supportChat.ticket}${id}/`,
    {
      ...data
    }
  )

/**
 * Получения расписания меню по складу буфета на определенную дату
 * @param {Object} payload - Объект с параметрами запроса, включая фильтры, даты и параметры пагинации.
 * @param {string} payload.params.storehouse - Идентификатор выбранного склада.
 * @param {string} [payload.params.date] - Выбранная дата.
 * @param {number} [payload.params.page] - Текущая страница.
 * @param {number} [payload.params.page_size] - Количество записей на странице.
 * @returns {Promise<Object>}
 */
export const fetchBuffetMenuSchedule = (payload) =>
  axios.get(`${Vue.config.server}/${Vue.config.api.superadmin.buffet.menuSchedule}`, {
    ...payload
  })