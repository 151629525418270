<template>
  <v-container class="fs-organizer-container">
    <Title layout>{{ title }}</Title>
    <v-form ref="formDish">
      <v-layout row justify-space-around wrap>
        <v-flex class="pr-2" md6>
          <v-text-field
            :value="item.name"
            label="Наименование"
            @input="setValue('name', $event)"
          ></v-text-field>
          <v-text-field
            v-model="item.price"
            :rules="[ v => /^(\d+|\d+\.\d{1,2}|)$/.test(v) || 'Неверный формат цены' ]"
            label="Цена, руб. (на 100 г)"
          ></v-text-field>
            <v-text-field
              :value="item.energy"
              label="Энергетическая ценность, кКал/кДж (на 100 г)"
              @input="setValue('energy', $event)">
            </v-text-field>
          <v-text-field
              :value="item.protein"
              label="Белки, г (на 100 г)"
              @input="setValue('protein', $event)"
            ></v-text-field>
            <v-text-field
              :value="item.fats"
              label="Жиры, г (на 100 г)"
              @input="setValue('fats', $event)"
            ></v-text-field>
            <v-text-field
              :value="item.carbs"
              label="Углеводы, г (на 100 г)"
              @input="setValue('carbs', $event)"
            ></v-text-field>
          <v-autocomplete
             v-if = "setting('recipe_food_category')"
             label="Раздел"
             v-model="currentFoodCategory"
             :items="foodCategories"
             clearable
             item-text="name"
             item-value="id"
          ></v-autocomplete>
        </v-flex>
        <v-flex class="pl-2" md6>
          <v-text-field
            :value="item.vitaminA"
            label="Витамин A (мкг на 100 г)"
            @input="setValue('vitaminA', $event)"
          ></v-text-field>
          <v-text-field
            :value="item.vitaminB1"
            label="Витамин В1 (мкг на 100 г)"
            @input="setValue('vitaminB1', $event)"
          ></v-text-field>
          <v-text-field
              :value="item.vitaminC"
              label="Витамин C (мкг на 100 г)"
              @input="setValue('vitaminC', $event)"
            ></v-text-field>
            <v-text-field
              :value="item.vitaminE"
              label="Витамин E (мкг на 100 г)"
              @input="setValue('vitaminE', $event)">
            </v-text-field>
            <v-text-field
              :value="item.miniralFe"
              label="Минеральные вещества Fe (мкг на 100 г)"
              @input="setValue('miniralFe', $event)">
            </v-text-field>
          <v-text-field
              :value="item.miniralCa"
              label="Минеральные вещества Ca (мкг на 100 г)"
              @input="setValue('miniralCa', $event)"
            ></v-text-field>
            <v-text-field
              :value="item.miniralMg"
              label="Минеральные вещества Mg (мкг на 100 г)"
              @input="setValue('miniralMg', $event)"
            ></v-text-field>
        </v-flex>
        <v-flex md12>
            <v-text-field
              v-if = "setting('recipe_food_category')"
              :value="item.recipe"
              label="Номер рецепта"
              @input="setValue('recipe', $event)"
             ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-checkbox
            v-model="item.isAdditional"
            label="Дополнительное"
          ></v-checkbox>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="dishAgeCategoryHeaders"
            :items="item.ageCategoires"
            class="elevation-1"
            hide-actions
          >
            <template v-slot:items="props">
              <tr>
                <td>{{ props.item.name }}</td>
                <td>
                  <v-text-field
                    :value="props.item.gramms"
                    @input="setValue(`weight${props.item.id}`, $event)"
                  ></v-text-field>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-flex text-md-right mt-3>
        <v-btn class="mx-0" @click="$router.push({ name: 'Dishes' })">Назад</v-btn>
        <v-btn class="primary mx-0 ml-2" @click="update()">Сохранить</v-btn>
      </v-flex>
    </v-form>
  </v-container>
</template>

<script>
import Title from '@/ui-components/Title'

import { mapGetters, mapMutations } from 'vuex'
import { getOneDish, updateDish } from '@/router/routes/organizer'
import store from '@/store'
import DishFactory from '@/factories/organizer/dish-factory'
import numable from '@/mixins/balancable'
import Vue from 'vue'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'DishEdit',
  mixins: [ numable ],
  components: {
    Title
  },
  data() {
    return {
      item: new DishFactory(),
      dishAgeCategoryHeaders: [
        { text: 'Возрастная категория', value: 'age-category' },
        { text: 'Выход блюда, г', value: 'gramms' }
      ],
      currentFoodCategory: null,
    }
  },
  computed: {
    title() {
      return `Блюдо: ${this.item.name}`
    },
    ...mapGetters([ 'foodCategories', 'setting' ])
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    setValue(key, value) {
      this.item[key] = value
    },
    async init() {
      try {
        const response = await getOneDish(this.$route.params.id)
        const {
          data: { data: dish }
        } = response

        this.item = {
          id: dish.id,
          name: dish.name,
          foodCategory: dish.food_category,
          recipe: dish.recipe,
          energy: dish.energy_value || 0,
          protein: dish.protein || 0,
          fats: dish.fat || 0,
          carbs: dish.carbohydrates || 0,
          price: dish.price || 0,
          isAdditional: dish.is_additional,
          vitaminA: dish.vitamin_a || 0,
          vitaminB1: dish.vitamin_b1 || 0,
          vitaminC: dish.vitamin_c || 0,
          vitaminE: dish.vitamin_e || 0,
          miniralCa: dish.miniral_ca || 0,
          miniralFe: dish.miniral_fe || 0,
          miniralMg: dish.miniral_mg || 0,
          ageCategoires: [
            {
              id: 1,
              alias: 'weightPrimary',
              name: '7-11 лет',
              gramms: dish.weight_primary || 0
            },
            {
              id: 2,
              alias: 'weightMiddle',
              name: '12 лет и старше',
              gramms: dish.weight_middle || 0
            }
          ]
        }
        this.currentFoodCategory = dish.food_category
        this.item.price = this.convertToRubles(this.item.price)

      } catch (error) {
        // notify.error('Ошибка получения блюда') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async update() {
      if (!this.$refs.formDish.validate()) return

      try {
        const PAYLOAD = {
          id: this.item.id,
          data: {
            id: this.item.id,
            name: this.item.name,
            foodCategory: this.currentFoodCategory || null,
            recipe: this.item.recipe,
            price: this.convertToCents(this.item.price) || 0,
            energyValue: this.item.energy || 0,
            protein: this.item.protein || 0,
            fat: this.item.fats || 0,
            carbohydrates: this.item.carbs || 0,
            vitaminA: this.item.vitaminA || 0,
            vitaminB1: this.item.vitaminB1 || 0,
            vitaminC: this.item.vitaminC || 0,
            vitaminE: this.item.vitaminE || 0,
            miniralCa: this.item.miniralCa || 0,
            miniralFe: this.item.miniralFe || 0,
            miniralMg: this.item.miniralMg || 0,
            isAdditional: this.item.isAdditional,
            weightMiddle: this.item.weight2 || this.item.ageCategoires[1].gramms,
            weightPrimary: this.item.weight1 || this.item.ageCategoires[0].gramms
          }
        }

        const response = await updateDish(PAYLOAD)

        if (response.data.success) {
          notify.success(Vue.config.notifications.organizer.dishUpdated)
        }
      } catch (error) {
        // notify.error('Ошибка обновления блюда') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
