<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout
      class="fs-complex"
      row
      wrap
      mb-3
    >
      <div class="md4 mb-3" style="display: flex">
        <h1>{{ title }}</h1>
	      <context-help-modal :context-key="contextKeys.admin.students.list" />
      </div>
    </v-layout>
    <v-layout
      row
      mb-3
      wrap
    >
      <v-flex md2 class="d-flex">
	      <with-tooltip text="Выбор класса">
	        <v-autocomplete
	          :value="selectedClassId"
	          :items="classes"
	          :outline="darkTheme"
	          :label="!darkTheme ? 'Класс' : ''"
	          :placeholder="darkTheme ? 'Класс' : ''"
	          @change="changeClass"
	          item-text="name"
	          item-value="id"
	          dense
	          flat
	        />
	      </with-tooltip>
      </v-flex>
      <v-flex class="d-flex ml-3 flex-shrink-0 flex-grow-0" v-if="isShowChangeClassPrivilege" row xs4 xs2 >
        <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            style="display: none"
            lazy
            transition="scale-transition"
            min-width="290px"
            max-width="290px"
        >
            <template v-slot:activator="{ on: on }">
              <v-text-field
                v-model="changeExtPrivilegeDate"
                prepend-icon="event"
                readonly
                label="Дата окончания льготы"
                v-on="on"
                width="80px"
              ></v-text-field>
          </template>
          <v-date-picker
            locale="ru"
            v-model="changeExtPrivilegeDate"
          ></v-date-picker>
        </v-menu>
        <v-btn
            class="white--text ml-5"
            @click="changePrivilege"
            color="primary"
            style="
              max-width: 200px;
              min-width: 150px;
            "
        >
          Продлить льготу
        </v-btn>
      </v-flex>
      <v-flex class="d-flex ml-3 flex-shrink-0 flex-grow-0" v-if="isShowRemoveClassPrivilege" row xs4 xs2>
        <v-btn
            @click="() => {this.showConfirmRemovePrivilegeModal = true}"
            color="primary"
            style="
              max-width: 200px;
              min-width: 150px;
            "
        >
          Снять льготу
        </v-btn>
        <ConfirmModal
          :dialog.sync="showConfirmRemovePrivilegeModal"
          @confirm="handlerConfirmRemovePrivilegeModal"
          :btnsClass="'fs-btn fs-teacher-btn__cancel'"
        >
          <template v-slot:headline>Подтверждение</template>
          <template v-slot:body>
            <div>
              Подтвердите снятие льготы у всех учеников выбранного класса
            </div>
          </template>
        </ConfirmModal>
      </v-flex>
      <v-flex md6></v-flex>
    </v-layout>
    <v-layout
      wrap
      class="fs-teacher-order-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          :pagination.sync="pagination"
          class="elevation-1 fs-admin-table local-table-style"
          item-key="name"
          dense
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>
                <v-tooltip bottom v-if="props.item.isFake">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">
                      warning
                    </v-icon>
                  </template>
                  По ученику получены неполные данные, работа с ним временно заблокирована.
                </v-tooltip>
                {{ props.item.name }}
              </td>
              <td>
	              <with-tooltip text="Открыть карточку ученика в режиме просмотра">
	                <v-btn
	                  flat
	                  @click="municipalityView ? $router.push({
	                    name: 'MunicipalityStudentsView',
	                    params: {
	                      id: props.item.id,
	                      filters: { classID: selectedClassId, schoolId: schoolId },
	                      schoolId: schoolId
	                    }
	                  }): $router.push({
	                    name: 'AdminStudentsView',
	                    params: { id: props.item.id, filters: { classID: selectedClassId } }
	                  })"
	                  class="fs-btn fs-teacher-btn__details"
	                >Смотреть</v-btn>
	              </with-tooltip>
	              <with-tooltip text="Открыть карточку ученика в режиме редактирования">
	                <v-btn
	                  flat
	                  v-if="!municipalityView"
	                  @click="$router.push({
	                    name: 'AdminStudentsEdit',
	                    params: { id: props.item.id, filters: { classID: selectedClassId } }
	                  })"
	                  class="fs-btn fs-teacher-btn__edit"
	                >Изменить</v-btn>
	              </with-tooltip>
              </td>
              <td>{{ props.item.concession.name }} ({{ props.item.concession.price }} руб.)</td>
              <td>
	              <v-flex>
		              <v-tooltip top v-if="props.item.hasCustomMealTypeSettings">
			              <template v-slot:activator="{ on }">
				              <v-icon
					              style="cursor: pointer"
					              color="primary"
					              v-on="on"
				              >info</v-icon>
			              </template>
			              <span>У ученика включено вариативное питание</span>
		              </v-tooltip>
		              {{
		                props.item.foodType.name
		                  ? props.item.foodType.name + '(' + props.item.foodType.price + ' руб.)'
		                  : 'отсутствует'
		              }}
	              </v-flex>
              </td>
              <td>{{
                props.item.agreementAcceptionDate
                  ? (new Date(props.item.agreementAcceptionDate)).toLocaleDateString('ru-ru')
                  : ''
              }}</td>
              <td>{{
                props.item.offlineContractAcceptionDate
                  ? (new Date(props.item.offlineContractAcceptionDate)).toLocaleDateString('ru-ru')
                  : ''
              }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getClasses, getFilteredStudents } from '@/router/routes/admin'
import {
  getClasses as getClassesDepartment,
  getFilteredStudents as getFilteredStudentDepartment,
} from '@/router/routes/department'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import Vue from 'vue'
import scrollable from '@/mixins/scrollable'
import converter from '@/helpers/converter'
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal.vue'
import notify from '@/utils/notifications/toastNotification'
import WithTooltip from '@/components/common/Tooltip/Tooltip.vue'
import { ConfirmModal } from '@/components/common'
import { changeClassExtDatePrivilege, removeClassPrivilege } from '@/router/routes/teacher'

export default {
  name: 'StudentIndex',
  components: {
    WithTooltip,
    ContextHelpModal,
    ConfirmModal,
  },
  mixins: [ scrollable ],
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      darkTheme: Vue.config.env.darkTheme,
      title: 'Список учеников',
      pagination: {
        sortBy: 'lastName',
        descending: false,
        rowsPerPage: -1
      },
      headers: [
        { text: 'Лицевой счёт', value: 'id', width: '90%' },
        { text: 'ФИО ученика', value: 'lastName' },
        { text: 'Операции', value: 'operations' },
        { text: 'Социальная выплата', value: 'concession' },
        { text: 'Тип питания', value: 'food-type' },
        { text: 'Дата принятия оферты', value: 'agreementAcceptionDate' },
        { text: 'Дата принятия бумажной оферты', value: 'offlineContractAcceptionDate' }
      ],
      items: [],
      classes: [],
      selectedClassId: null,
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10),
      LIMIT: Vue.config.general.limit,
      savedFilters: null,
      schoolId: null,
      isShowChangeClassPrivilege: store.getters.setting('administrator_change_class_privilege_date'),
      isShowRemoveClassPrivilege: store.getters.setting('administrator_remove_class_privilege'),
      schoolName: null,
      offset: 0,
      changeExtPrivilegeDate: null,
      showConfirmRemovePrivilegeModal: false,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
  },
  props: {
    municipalityView: {
      type: Boolean,
      required: false,
      default: false
    },
    municipalitySchoolId: {
      type: Number,
      required: false,
      default: null
    },
    municipalitySchoolName: {
      type: String,
      required: false,
      default: null
    }
  },
  created() {
    if (this.$route.params.filters) {
      this.savedFilters = this.$route.params.filters
    }
    window.addEventListener('scroll', this.scrollHandler)
    this.schoolId = _.get(this.currentUser, 'school.id', this.municipalityView ? this.municipalitySchoolId : null)
    this.schoolName = _.get(this.currentUser, 'school.short_name', this.municipalityView ? this.municipalitySchoolName : null)
    this.init()
  },
  destroyed() {
    this.removeScrollHandler()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    handlerConfirmRemovePrivilegeModal(confirm) {
      if (confirm) {
        this.removeClassPrivilege()
      }
    },
    changePrivilege() {
      if (this.changeExtPrivilegeDate !== null) {
        const resp = this.processChangePrivilege().then(response => {
          if (response.status === 200) {
            notify.success('Льгота была успешно продлена у всего класса')
          }
          this.resetData()
          this.init()
        }).catch(error => {
          console.log(error)
          notify.error('Ошибка продления льготы')
        }).finally(() => {
          store.commit('setShowPreloader', false)
        })
      } else {
        notify.error('Поле даты окончания льготы должно быть заполнено')
      }
    },
    removeClassPrivilege() {
      const resp = this.processRemoveClassPrivilege().then(response=> {
        if (response.status === 200) {
          notify.success('Льгота успешно снята у всего класса')
        }
        this.resetData()
        this.init()
      }).catch(error => {
        notify.error(error)
      }).finally(() => {
        store.commit('setShowPreloader', false)
      })
    },
    async processRemoveClassPrivilege() {
      const PAYLOAD = {
        pupils: _.map(this.items, item => {
          return item.id
        })
      }
      return await removeClassPrivilege(PAYLOAD)
    },
    async processChangePrivilege() {
      const PAYLOAD = {
        extDate: this.changeExtPrivilegeDate,
        pupils: _.map(this.items, item => {
          return item.id
        })
      }
      return await changeClassExtDatePrivilege(PAYLOAD)
    },
    resetData() {
      this.items = []
    },
    convertToRub(value) {
      return converter(value, 'pennyToRub')
    },
    changeClass(value) {
      this.resetData()
      this.selectedClassId = value
      this.loadMore()
    },
    async init() {
      await this.getClasses()
    },
    async getClasses() {
      try {
        const PAYLOAD = {
          limit: Vue.config.general.classLimit,
          offset: this.offset,
          schoolId: this.schoolId
        }
        const method = this.municipalityView ? getClassesDepartment : getClasses
        const response = await method(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id = null, name = null, number = null } = item
          return {
            id,
            name,
            number
          }
        })
        this.classes = [ ...this.classes, ...itemsArray ]
        if (this.savedFilters) {
          this.selectedClassId = this.savedFilters.classID
        } else {
          this.selectedClassId = this.classes[0].id
        }
        this.loadMore()
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadMore() {
      try {
        const PAYLOAD = {
          schoolId: this.schoolId,
          classId: this.selectedClassId,
          params: {
            limit: Vue.config.general.limit,
            offset: this.offset
          }
        }
        const method = this.municipalityView ? getFilteredStudentDepartment : getFilteredStudents
        const response = await method(PAYLOAD)
        const {
          data: { data: students }
        } = response
        const itemsArray = students.map(item => {
          return {
            id: item.id,
            name: item.fullName,
            lastName: item.lastName,
            isFake: item.isFake,
            concession: {
              id: _.get(item, 'privilege.id', null),
              name: _.get(item, 'privilege.name', ''),
              price: converter(_.get(item, 'privilege.price', null), 'pennyToRub')
            },
            foodType: {
              id: _.get(item, 'foodType.id', null),
              name: _.get(item, 'foodType.name', ''),
              price: converter(_.get(item, 'foodType.price', null), 'pennyToRub')
            },
	          hasCustomMealTypeSettings: item.hasCustomMealTypeSettings,
            schoolClass: {
              id: _.get(item, 'schoolClass.id', null),
              name: _.get(item, 'schoolClass.name', '')
            },
            agreementAcceptionDate: _.get(item, 'agreementAcceptionDate', ''),
            offlineContractAcceptionDate: _.get(
              item,
              'offlineContractAcceptionDate',
              ''
            )
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    getClassLiter(name) {
      return name.split(' ')[1]
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>

<style>
.local-table-style .v-datatable thead th.column.sortable {
  cursor: pointer;
  outline: 0;
  width: 1%;
}
</style>
