<script>
import EsiaButton from "@/components/esia/components/EsiaButton.vue";
import { getEsiaStatus, setEsiaSnils } from '@/router/routes/organizer'
import notify from '@/utils/notifications/toastNotification'
import store from '@/store'

export default {
  name: 'EsiaSettings',
  components: {EsiaButton},
  data() {
    return {
      snilsInput: null,
      snilsId: null
    }
  },
  methods: {
    async getEsiaData() {
      const { data } = await getEsiaStatus()
      this.snilsInput = data.esia_snils
      this.snilsId = data.esia_id
      store.commit('setShowPreloader', false)
    },
    async saveSnils() {
      const snils = this.snilsInput.replaceAll(' ', '').replaceAll('-', '')
      if (snils !== '' && snils.length === 11 ) {
        const response = await setEsiaSnils({snils: snils})
        if (response.status === 200) {
          notify.success('Данные успешно сохранены')
        } else {
          notify.error('Не удалось сохранить данные')
        }
      } else {
        notify.error('Укажите корректный снилс')
      }
      store.commit('setShowPreloader', false)
    }
  },
  async mounted() {
    await this.getEsiaData()
  }
}
</script>

<template>
<v-container>
  <v-card>
    <v-card-title>
      <h3>Статус связи профиля с ГосУслугами</h3>
    </v-card-title>
    <v-card-text>
      <div>
        Статус:
        <span v-if="snilsId"><v-icon color="success" size="14">check_circle</v-icon> подключено</span>
        <span v-else><v-icon>cancel</v-icon> не подключено</span>
      </div>
      <div class="d-flex row justify-space-between mt-3">
        <div>
          <v-text-field
              v-model="snilsInput"
              type="text"
              name="snils"
              label="СНИЛС (Формат: ХХХХХХХХХХХ, 11 цифр)"
              prepend-icon="badge"
          ></v-text-field>
        </div>
        <div></div>
        <div class="d-flex row align-center">
          <v-btn color="primary" @click="saveSnils">
            Сохранить
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</v-container>
</template>

<style scoped>

</style>