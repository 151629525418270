<script setup>
import { computed, onMounted, ref } from 'vue'
import DatePickerDL from '@/components/moduleadmin/DepartmentLogsNew/components/DatePickerDL.vue'
import SelectorDL from '@/components/moduleadmin/DepartmentLogsNew/components/SelectorDL.vue'
import SearchButtonDL from '@/components/moduleadmin/DepartmentLogsNew/components/SearchButtonDL.vue'
import { getLogSearch } from '@/router/routes/superadmin'
import store from '@/store'
import ContentTableDL from '@/components/moduleadmin/DepartmentLogsNew/components/ContentTableDL.vue'

const filters = ref({
  filter_dateFrom: null,
  filter_dateTo: null,
  filter_department: null,
  filter_school: null,
  filter_supplier: null,
  filter_logType: null,
  filter_logAction: null,
  filter_objectId: null,
  filter_search: null,
})

const serverSideData = ref({ data: [], success: false, pagination: {} })
const contentData = computed(() => serverSideData.value.data)
const paginationData = ref({
  descending: false,
  page: 1,
  rowsPerPage: 10,
  totalItems: 0
})
const objectIdFieldBlock = ref(false)

const input_select = (target, value) => {
  filters.value[target] = value
}

const updatePaginationFromServer = () => {
  // Пагинация с сервера обновляется при изменении фильтров, либо при первом запуске страницы.
  paginationData.value.totalItems = serverSideData.value.pagination?.count || 0
}

const resetPage = () => {
  // Переход на первую страницу необходим при изменении фильтров, чтобы пользователь
  // не мог запросить несуществующую страницу. Например, третью из двух.
  paginationData.value.page = 1
}

const doSearch = async ({ reloadPagination } = {}) => {
  if (reloadPagination) resetPage()
  const { data } = await getLogSearch({
    department_id: filters.value.filter_department,
    school_id: filters.value.filter_school,
    supplier_id: filters.value.filter_supplier,
    start_date: filters.value.filter_dateFrom,
    end_date: filters.value.filter_dateTo,
    object_id: filters.value.filter_supplier || filters.value.filter_objectId,
    content_type__model: filters.value.filter_logType,
    action: filters.value.filter_logAction,
    search: filters.value.filter_search,
    page_size: paginationData.value.rowsPerPage,
    page: paginationData.value.page
  })
  serverSideData.value = data
  if (reloadPagination) updatePaginationFromServer()
  store.commit('setShowPreloader', false)
}

const fetchNextPageFromServer = () => {
  // В случае перелистывания страниц общее кол-во элементов остаётся неизменным, поэтому отключаем
  // загрузку данных пагинации с сервера reloadPagination: false.
  doSearch({ reloadPagination: false })
}

const updatePagination = (newValue) => {
  // Обновляем данные с сервера после изменения страницы или кол-ва строк на странице
  paginationData.value = newValue
  fetchNextPageFromServer()
}

onMounted(async () => {
  // Изначально таблице неизвестно, сколько всего данных в бд (для пагинации).
  // Поэтому устанавливаем значение только после ответа от севера.
  await doSearch({ reloadPagination: true })
})
</script>

<template>
<v-container fluid>
  <div class="d-flex">
    <div class="sidebar">
      <v-card>
        <v-card-text>
          <DatePickerDL
            select-target="filter_dateFrom"
            @value-select-func="(target, value) => input_select(target, value)"
          />
          <DatePickerDL
            select-target="filter_dateTo"
            @value-select-func="(target, value) => input_select(target, value)"
          />
          <SelectorDL
            target-instance="filter_department"
            @value-select-func="(target, value) => input_select(target, value)"
          />
          <SelectorDL
            target-instance="filter_school"
            :lazy="filters.filter_department"
            @value-select-func="(target, value) => input_select(target, value)"
          />
          <SelectorDL
            target-instance="filter_supplier"
            @value-select-func="(target, value) => input_select(target, value)"
          />
          <SelectorDL
            target-instance="filter_logType"
            @value-select-func="(target, value) => input_select(target, value)"
          />
          <SelectorDL
            target-instance="filter_logAction"
            @value-select-func="(target, value) => input_select(target, value)"
          />
          <v-text-field
            v-model="filters.filter_objectId"
            label="Идентификатор"
            :disabled="objectIdFieldBlock"
          />
          <v-text-field
              v-model="filters.filter_search"
              label="Поиск по тексту"
          />
          <SearchButtonDL
              @filter-search-func="() => doSearch({ reloadPagination: true })"
              class='w-100 ml-0'
          />
        </v-card-text>
      </v-card>
    </div>
    <div class="ml-3 d-flex flex-column">
      <div>
        <ContentTableDL
          :table-data="contentData"
          :pagination='paginationData'
          @updatePagination='updatePagination'
        />
      </div>
    </div>
  </div>
</v-container>
</template>

<style scoped>
.sidebar {
  max-width: 250px;
  min-width: 250px;
}
.bottombar {
  max-height: 50px;
  min-height: 50px;
}
</style>
