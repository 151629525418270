<template>
  <v-container fluid class="fs-organizer-container">
    <v-layout class="fs-complex" row mb-3>
      <div class="md4 mb-3 d-flex">
        <h1>{{ title }}</h1>
        <context-help-modal :context-key="contextKeys.supplier.users.info" />
      </div>
    </v-layout>
    <v-layout>
      <v-layout row>
        <v-flex mb-3 md2>
          <v-btn class="fs-btn fs-btn--secondary mx-0" flat @click="newUser"
            >Новый пользователь</v-btn
          >
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout class="fs-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="users"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.username }}</td>
              <td>
                <v-icon size="12" color="green" v-if="props.item.esia_snils">
                  check_circle
                </v-icon>
                <v-icon size="12" color="grey" v-else>
                  cancel
                </v-icon>
              </td>
              <td>{{ props.item.is_active ? 'Да' : 'Нет' }}</td>
              <td>
                <template v-if="props.item.roles?.length > 0">
                  <v-chip small>{{
                    getUsersRoles(props.item.roles)[0].name
                  }}</v-chip>

                  <v-menu bottom left v-if="props.item.roles?.length > 1">
                    <template v-slot:activator="{ on }">
                      <v-chip small outline v-on="on">
                        + еще {{ props.item.roles.length - 1 }}</v-chip
                      >
                    </template>
                    <v-list subheader dense>
                      <v-list-tile
                        disabled
                        inactive
                        v-for="({ name, id }, idx) in getUsersRoles(
                          props.item.roles
                        )"
                      >
                        <v-chip :key="id" small>{{ name }}</v-chip>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </template>
              </td>
              <td>
                <v-flex row>
                  <v-btn
                    icon
                    x-small
                    :title="'Редактировать'"
                    @click="editUser(props.item)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    x-small
                    :title="'Заблокировать'"
                    :disabled="!props.item.is_active"
                    @click="blockUser(props.item)"
                  >
                    <v-icon>block</v-icon>
                  </v-btn>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <ConfirmModal
      :dialog.sync="dialogs.user"
      :confirmBtnText="dialogs.new ? 'Создать' : 'Редактировать'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="userDialogActionsHandler"
    >
      <template v-slot:headline>
        {{
          dialogs.new
            ? 'Добавление нового пользователя'
            : 'Редактирование пользователя'
        }}
      </template>
      <template v-slot:body>
        <v-flex>
          <v-text-field
            v-model="user.username"
            type="text"
            label="Логин"
          ></v-text-field>
          <v-text-field
            v-model="user.password"
            type="text"
            label="Пароль"
          ></v-text-field>
          <v-text-field
            v-model="confirmPassword"
            type="text"
            label="Подтверждение пароля"
          ></v-text-field>
          <v-text-field
            v-model="user.esia_snils"
            type="text"
            label="СНИЛС (11 цифр), опционально"
          ></v-text-field>
          <!-- <v-select
            v-if="dialogs.edit"
            label="Активен"
            v-model="user.is_active"
            :items="states"
            item-text="name"
            item-value="value"
          ></v-select> -->

          <v-autocomplete
            class="fs-autocomplete"
            v-model="user.roles"
            label="Роль"
            multiple
            :items="roles"
            item-text="name"
            item-value="id"
            clearable
            chips
            :search-input.sync="search"
            @change="search = null"
          >
            <template v-slot:selection="data">
              <v-chip
                :selected="data.selected"
                close
                class="chip--select-multi"
                @input="remove(data.item)"
              >
                <strong>{{ data.item.name }}</strong
                >&nbsp;
              </v-chip>
            </template>
          </v-autocomplete>
        </v-flex>
      </template>
    </ConfirmModal>
    <ConfirmModal
      :dialog.sync="dialogs.block"
      :confirmBtnText="'Заблокировать'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="blockUserHandler"
    >
      <template v-slot:headline>Блокировка пользователя</template>
      <template v-slot:body>
        Вы действительно хотите заблокировать пользователя?
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import Vue from 'vue'
import {
  getUsers,
  createUser,
  blockUser,
  updateUser
} from '@/router/routes/organizer'
import store from '@/store'
import { ConfirmModal } from '@/components/common'
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal.vue'
import notify from '@/utils/notifications/toastNotification'

class User {
  constructor(item) {
    this.id = (item && item.id) || undefined
    this.username = (item && item.username) || undefined
    this.password = (item && item.password) || undefined
    // this.is_active = item && item.is_active || undefined
    this.roles = (item && item.roles) || []
  }
}
export default {
  name: 'OrganizerUsers',
  components: {
    ContextHelpModal,
    ConfirmModal
  },
  data() {
    return {
      title: 'Пользователи',
      contextKeys: Vue.config.contextHelp,
      headers: [
        { text: 'Логин', value: 'username' },
        { text: 'ЕСИА', value: 'esia_snils' },
        { text: 'Активность', value: 'is_active' },
        { text: 'Роли', value: 'roles' },
        { text: 'Действия', value: 'actions' }
      ],
      users: [],
      dialogs: {
        user: false,
        new: false,
        block: false,
        edit: false
      },
      user: {
        id: undefined,
        username: undefined,
        password: undefined,
        esia_snils: null,
        // is_active: undefined,
        roles: []
      },
      confirmPassword: null,
      states: [
        { name: 'Да', value: true },
        { name: 'Нет', value: false }
      ],
      roles: Vue.config.general.supplierSubRoles,
      rolesMap: new Map(
        Vue.config.general.supplierSubRoles.map((item) => [item.id, item])
      ),
      search: null
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    remove(item) {
      this.user = {
        ...this.user,
        roles: this.user.roles.filter((roleId) => roleId !== item.id)
      }
    },
    newUser() {
      this.user = new User()
      this.dialogs.user = this.dialogs.new = true
    },
    blockUser(item) {
      this.user = new User(item)
      this.dialogs.block = true
    },
    editUser(item) {
      this.user = new User(item)
      this.dialogs.user = this.dialogs.edit = true
    },
    async userDialogActionsHandler(confirm) {
      if (!confirm) {
        this.dialogs.user = this.dialogs.new = this.dialogs.edit = false
        return
      }
      if (this.user.password !== this.confirmPassword) {
        notify.error('Пароли должны совпадать') //pass
        this.confirmPassword = null
        this.dialogs.user = true
        return
      }
      if (this.dialogs.new) {
        await this.createUser()
      } else {
        await this.updateUser()
      }
      this.confirmPassword = null
    },
    async createUser() {
      try {
        await createUser(this.user)
        notify.success('Пользователь успешно добавлен')
        await this.getUsers()
      } catch (error) {
        // notify.error('Не удалось добавить пользователя') // backend handler added (exception_message)
        this.dialogs.user = true
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async updateUser() {
      try {
        await updateUser(this.user)
        notify.success('Пользователь успешно обновлен')
        await this.getUsers()
      } catch (error) {
        // notify.error('Не удалось обновить пользователя') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async blockUserHandler(confirm) {
      if (!confirm) return
      try {
        await blockUser(this.user.id)
        notify.success('Пользователь успешно заблокирован')
        await this.getUsers()
      } catch (error) {
        // notify.error('Не удалось заблокировать пользователя') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getUsers() {
      try {
        const response = await getUsers()
        const {
          data: { data: users }
        } = response
        this.users = users
      } catch (error) {
        // notify.error('Не удалось загрузить пользователей') // backend handler added (exception_message)
      } finally {
        this.dialogs.user = this.dialogs.new = this.dialogs.edit = false
        store.commit('setShowPreloader', false)
      }
    },
    getUsersRoles(roles) {
      return roles.map((roleId) => this.rolesMap.get(roleId))
    }
  }
}
</script>
