<script setup>
import {
  getDepartments,
  getLogActionsList,
  getLogModelsList,
  getSchoolsByDepartment,
  getSuppliersList
} from '@/router/routes/superadmin'
import { onMounted, ref, watch } from 'vue'
import store from '@/store'

const props = defineProps([
  'targetInstance', 'lazy'
])

const emits = defineEmits([
  'value-select-func'
])

const definitions = {
  'filter_department': {
    get: getDepartments,
    labelName: 'Департамент',
    values: {
      name: 'shortName',
      value: 'id'
    }
  },
  'filter_school': {
    get: getSchoolsByDepartment,
    labelName: 'Школа',
    values: {
      name: 'short_name',
      value: 'id'
    }
  },
  'filter_supplier': {
    get: getSuppliersList,
    labelName: 'Комбинат',
    values: {
      name: 'short_name',
      value: 'id'
    }
  },
  'filter_logType': {
    get: getLogModelsList,
    labelName: 'Раздел',
    values: {
      name: 'name',
      value: 'model'
    }
  },
  'filter_logAction': {
    get: getLogActionsList,
    labelName: 'Действие',
    values: {
      name: 'translate',
      value: 'action'
    }
  },
}

const items = ref()
const selectedItem = ref()

const onSelect = () => {
  emits('value-select-func', props.targetInstance, selectedItem.value)
}

const getData = async (params) => {
  const { data } = await definitions[props.targetInstance].get(params)
  items.value = data.data ? data.data : data
  store.commit('setShowPreloader', false)
}

onMounted( async () => {
  console.log(props.lazy)
  if (props.lazy === null) {
    return
  }
  await getData()
})

watch(() => props.lazy, async (newValue, oldValue) => {
  if (newValue !== null && newValue !== oldValue) {
    await getData(props.lazy)
  }
})

</script>

<template>
  <v-select
    v-model="selectedItem"
    @change="onSelect"
    :multiple="definitions[targetInstance].multiple"
    :items="items"
    :item-text="definitions[targetInstance].values.name"
    :item-value="definitions[targetInstance].values.value"
    :label="definitions[targetInstance].labelName"
    :aria-hidden="true"
  ></v-select>
</template>

<style scoped>

</style>