<script setup>
import { ref } from 'vue'

const props = defineProps([
  'selectTarget'
])

const emits = defineEmits([
  'value-select-func'
])

const datepickerMenu = ref()
const selectedDate = ref()

const definitions = {
  'filter_dateFrom': {
    label: 'С даты'
  },
  'filter_dateTo': {
    label: 'По даты'
  }
}

const selectMenu = () => {
  datepickerMenu.value = false
  emits('value-select-func', props.selectTarget, selectedDate.value)
}
</script>

<template>
  <v-menu
    v-model="datepickerMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="selectedDate"
        prepend-icon="event"
        readonly
        :label="definitions[selectTarget].label"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      prev-icon="keyboard_arrow_left"
      next-icon="keyboard_arrow_right"
      locale="ru"
      first-day-of-week="1"
      no-title
      v-model="selectedDate"
      @input="selectMenu()"
    ></v-date-picker>
  </v-menu>
</template>

<style scoped>

</style>