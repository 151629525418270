import { Menu, Submenu } from '@/lib/menu'

const inform = new Submenu('Информирование').add(
  new Menu('Новости', 'Новости обновлений', 'UpdatesNews'),
  new Menu(
    'Плановое обновление системы',
    'Информация о плановом обновлении системы',
    'Alerts'
  )
)

export default class MainMenuFactory {
  constructor(settings) {
    const accounts = new Submenu('Учетные записи').add(
      new Menu(
        'Организаторы питания',
        'Создание и блокировка организаторов питания',
        'ModuleAdminOPList'
      ),
      new Menu(
        'Муниципальные пользователи',
        'Создание и блокировка муниципальных пользователей',
        'ModuleAdminMunicipalUsers'
      ),
      new Menu('Пользователи', 'Пользователи', 'UsersList'),
      new Menu(
        'Ограничение доступа',
        'Ограничение доступа',
        'ModuleAdminAccessLimitation',
        true
      ),
      new Menu(
        'Удаление пользователей',
        'Просмотр и удаление учеников по школам',
        'DeleteUsers'
      ),
      new Menu(
        'Буфетные пользователи',
        'Буфетные пользователи',
        'BuffetUserList'
      )
    )

    let menu = [
      new Menu('Авторизация', 'Реавторизация', 'ModuleAdminReauthorization'),
      inform,
      new Submenu('Логгеры').add(
        new Menu('Журнал', 'Журнал действий', 'ModuleAdminLogs'),
        new Menu(
          'Лог создания заявок',
          'Лог создания заявок на питание',
          'SuperadminOrderLogDefaultIndex',
          true
        ),
        new Menu(
          'Лог изменения заявок',
          'Лог изменения заявок на питание',
          'SuperadminOrdersLogsIndex',
          true
        ),
        new Menu('Лог департаментов', 'Лог департаментов', 'DepartmentLogs'),
        new Menu('Лог департаментов 2', 'Лог департаментов', 'DepartmentLogsNew'),
        new Menu(
          'Лог по ученику',
          'Лог действий в карточке ученика',
          'AdminTeacherPupilCardLogs'
        )
      ),
      accounts,
      new Submenu('Финансы').add(
        new Menu(
          'Взаимодействие с банком',
          'Редактирование настроек ОП',
          'ModuleAdminOPBank'
        ),
        new Menu(
          'Зачисление остатков',
          'Зачисление остатков',
          'SuperadminPayments'
        )
      ),
      new Menu(
        'Уведомления',
        'Просмотр уведомлений',
        'ModuleAdminNotifications',
        true
      ),
      new Submenu('Заявки').add(
        new Menu('Заявки', 'Заявки на питание', 'SuperadminOrdersIndex', true),
        new Menu(
          'Заявки v2',
          'Заявки на питание',
          'SuperadminOrdersIndexV2',
          true
        ),
        new Menu(
          'Формирование заявки',
          'Формирование заявки на прошедшую дату',
          'SuperadminOrderGeneration',
          true
        )
      ),
      new Submenu('Ученики').add(
        new Menu('Ученики', 'Работа с учениками', 'SuperadminPupilsList', true)
      ),
      new Menu('Настройки', 'Настройки', 'SuperadminSettings'),
      new Menu('Отчёты', 'Отчёты', 'SuperadminReports'),
      new Menu('Поиск ошибок', 'Поиск ошибок по UUID', 'ExceptionSearch'),
      new Menu('Синхронизация', 'Синхронизация', 'SyncData')
    ]

    // Добавление FAQ
    if (settings.support_chat_plugin?.value) {
      menu = menu.concat([
        new Menu(
          'Центр ответов и FAQ',
          'Центр ответов и FAQ',
          'SuperAdminFAQView'
        )
      ])
    }

    if (settings.mod_quality_control?.value) {
      menu = menu.concat([
        new Menu(
          'Контроль качества',
          'Контроль качества',
          'ModuleAdminQualityControlWrap'
        )
      ])
    }

    if (settings.vue_debug_enable?.value) {
      menu = menu.concat([new Menu('Debug', 'Debug Page', 'DebugComponent')])
    }

    if (settings.user_help?.value) {
      const menu = new Menu(
        'Справочная информация',
        'Справочная информация',
        'ModuleAdminUserHelp'
      )
      if (!inform.includes(menu)) inform.add(menu)
    }

    if (settings.boarding_school_department?.value) {
      accounts.add(
        new Menu(
          'Департамент Школы-интернат',
          'Департамент Школы-интернат',
          'BoardingSchoolsDepartmentsView'
        ),
        new Menu(
          'Пользователи Школы-интернат',
          'Пользователи Школы-интернат',
          'BoardingSchoolsUsersView'
        )
      )
    }

    if (settings.sirius?.value) {
      menu = menu.concat([
        new Menu('Расписание Меню', 'Расписание Меню', 'BuffetMenuSchedule')
      ])
    }

    return menu
  }
}
